import axios from 'axios';

const API_URL = '/api/v1/auth/';

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'login', {
          email: user.email,
          password: user.password
      })
      .then(response => {
        return response.data;
      });
  }

  logout() {
      return axios.post(API_URL + 'logout', {});
  }

  passwordReset(email) {
      return axios.post(API_URL + 'password_reset', {
          email: email
      });
  }

  register(user) {
    return axios.post(API_URL + 'register', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }
}

export default new AuthService();
