<template>
    <div
        class="top-bar-boxed h-[70px] md:h-[65px] z-[51] border-b border-white/[0.08] mt-12 md:mt-0 -mx-3 sm:-mx-8 md:-mx-0 px-3 md:border-b-0 relative md:fixed md:inset-x-0 md:top-0 sm:px-8 md:px-10 md:pt-10 md:bg-gradient-to-b md:from-slate-100 md:to-transparent dark:md:from-darkmode-700"
    >
        <div class="h-full flex items-center">
            <!-- BEGIN: Logo -->
            <router-link to="/" class="logo -intro-x md:flex xl:w-[180px] block">
                <span class="logo__text text-white text-lg ml-3">Musikalien-Verleih </span>
            </router-link>
            <!-- END: Logo -->

            <!-- BEGIN: Breadcrumb -->
            <nav aria-label="breadcrumb" class="-intro-x h-[45px] mr-auto">
                <ol class="breadcrumb breadcrumb-light">
                    <li class="breadcrumb-item">
                        <router-link to="/">Startseite</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">{{ name }}</li>
                </ol>
            </nav>
            <!-- END: Breadcrumb -->

            <!-- BEGIN: Logout -->
            <a href="#" class="-intro-x md:flex block text-white" @click="logout">
                abmelden
            </a>
            <!-- END: Logout -->
        </div>
    </div>
</template>

<script setup>
import {useRoute} from 'vue-router'
import {computed, ref} from 'vue'
import {useStore} from 'vuex';
import {useTopMenuStore} from '../../stores/top-menu';

const store = useStore();
const route = useRoute();
const name = computed(() => {
    const menu = useTopMenuStore();
    let currentPage = menu.menu.find(x => x.pageName === route.name);
    return currentPage ? currentPage.title : '';
});

const searchDropdown = ref(false);
const showSearchDropdown = () => {
    searchDropdown.value = true;
};
const hideSearchDropdown = () => {
    searchDropdown.value = false;
};

const logout = () => {
    store.commit('auth/logout');
};
</script>
