import $ from 'jquery';
import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import store from "./stores";
import axios from 'axios';
import globalComponents from "./global-components";
import utils from "./utils";
import "./assets/css/app.css";

window.$ = $;
window.axios = axios;
window.moment = require('moment-timezone');

import {createUpload}  from '@websanova/vue-upload/src/v3.js';
import driverHttpAxios from '@websanova/vue-upload/src/drivers/http/axios.js';
import globalMixin from "./utils/globalMixin";

if (store.state.auth.accessToken != null) {
    window.axios.defaults.headers.common = {'Authorization': `Bearer ${store.state.auth.accessToken}`};
}

window.axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        store.dispatch('auth/logout', store.state.auth.user).then(() => {
            router.push('/login')
                .then(ret => { });
        });
    } else {
        return Promise.reject(error);
    }
});

const app = createApp(App)
    .use(router)
    .use(store)
    .use(createPinia())
    .use(createUpload({
        plugins: {
            http: window.axios
        },
        drivers: {
            http: driverHttpAxios
        },
        options: {
            http: {
                Authorization: function() {
                    let store = JSON.parse(localStorage.vuex);
                    console.log(store);
                    return 'Bearer ' + store.$state.auth.accessToken;
                }
            }
        }
    }));

router.store = store

globalComponents(app);
utils(app);

app.mixin(globalMixin);

app.mount("#app");
