<template>
    <div>
        <ul class="spacer mb-2">
            <li>
                <button
                    @click="select"
                    class="btn btn-primary w-48"
                >
                    Dateien auswählen
                </button>
            </li>
        </ul>

        <hr/>

        <div class="media py-0">
            <div class="media-middle text-bold">
                Dateien
            </div>

            <div class="media-tight media-middle media-right">
        <span
            v-show="state._meta.state === 'uploading'"
            class="spinner"
        />

                <span class="text-muted text-sm mx-1">
            ({{ state._meta.percentComplete }}%)
        </span>

                {{ state._meta.state }}
            </div>
        </div>

        <div
            v-for="error in state.errors"
            class="text-danger"
        >
            {{ error.msg }}
        </div>

        <div
            v-for="file in state._files.all"
            class="media py-0"
        >
            <div class="media-middle">
                {{ file.name }}

                <div
                    v-if="file.error"
                    class="w-100 text-danger text-sm"
                >
                    {{ file.error.msg }}
                </div>

                <div class="w-100">
                    <button
                        v-show="file.state === 'queue' || file.state === 'progress' || file.state === 'upload'"
                        @click="file.clear()"
                        class="btn btn-sm btn-warning ml-5 w-18"
                    >
                        abbrechen
                    </button>
                </div>
            </div>

            <div class="media-tight media-middle media-right">
                <span
                    v-show="file.sending"
                    class="spinner"
                />
                <span class="text-muted text-sm mx-1">
                    ({{ file.percentComplete }}%)
                </span>
                {{ file.state }}
            </div>
        </div>

        <hr/>

        <div id="dropzone">
            (Dateien per Drag & Drop hier ablegen)
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex'
import {reactive, computed, onMounted, onBeforeUnmount} from 'vue';
import {useUpload} from '@websanova/vue-upload/src/v3.js';

export default {
    props: {
        id: Number,
        type: String,
    },

    emits: ['upload-done'],

    setup(props, context) {
        const store = useStore();
        const upload = useUpload();
        const state = reactive({
            files: [],
            errors: [],
            _files: computed(() => {
                return upload.files('demo-multi');
            }),
            _meta: computed(() => {
                return upload.meta('demo-multi');
            })
        });

        function triggerUploadDone(file) {
            context.emit('upload-done', file);
        }

        function select() {
            upload.select('demo-multi');
        }

        function reset() {
            upload.reset('demo-multi');
        }

        onMounted(() => {
            // NOTE: Important to do dropzoneId on mounted
            //       otherwise it won't find the element.

            upload.on('demo-multi', {
                url: '/api/v1/' + props.type,
                accept: '*/*',
                multiple: true,
                dropzoneId: 'dropzone',
                startOnSelect: true,
                maxFilesInProgress: 2,
                maxSizePerFile: 1024 * 1024 * 12,
                extensions: ['pdf', 'gif', 'png', 'jpg', 'jpeg'],
                onSelect: (files, res) => {
                    //console.log('onSelect');
                    //console.log(files);
                    //console.log(res);

                    state.errors = [];

                    if (res) {
                        state.errors.push(res);
                    }

                    // Add some additional data to the request.
                    upload.option('demo-multi', 'body', {
                        id: props.id,
                        type: props.type
                    });

                    //console.log(upload.meta('demo-multi'))
                    //console.log(upload.errors('demo-multi'))
                },
                onProgress(file, res) {
                    /*console.log('onProgress');
                    console.log(file);
                    console.log(res);*/
                },
                onSuccess: (file, res) => {
                    /*console.log('onSuccess');
                    console.log(file);
                    console.log(res);*/

                    // On success, we can update whatever we need
                    // to locally, for instance the user avatar.
                    state.files.push(res.data.item);

                    triggerUploadDone(res.data.item);
                },
                onError: (file, res) => {
                    /*console.log('onError');
                    console.log(file);
                    console.log(res);*/
                },
                onEnd(files, res) {
                    //console.log('onEnd');
                }
            });
        });

        onBeforeUnmount(() => {
            upload.off('demo-multi');
        });

        return {
            state,
            select,
            reset,
        };
    }
}
</script>

<style scoped>
#dropzone {
    height: 200px;
    line-height: 200px;
    border: dashed 2px;
    background-color: #fafafa;
    text-align: center;
}

.spinner::before {
    content: '+';
    margin: 0 5px;
    display: inline-block;
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(360deg)
    }
}

.media {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 5px 0;
}

.media:not(:last-child) {
    margin-bottom: 10px;
}

.media > div {
    display: flex;
    flex: 1;
    flex-flow: row wrap;
}

.media > .media-tight {
    flex: none;
    word-break: keep-all;
    white-space: nowrap
}

.media > .media-middle {
    align-items: center;
}

.media > .media-right {
    justify-content: right;
}
</style>
