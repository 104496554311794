<template>
    <div class="intro box mt-5" id="rental-form" v-if="this.loaded">
        <div class="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
            <h2 class="font-medium text-base mr-auto">Verleih anlegen</h2>
        </div>

        <div class="grid grid-cols-12 gap-6 pl-0 pr-5">

            <FormErrorBox />

            <div class="intro col-span-12 lg:col-span-12 pb-4">
                <div class="text-center mb-4">
                    <h2 class="text-2xl dark:text-white">Informationen</h2>
                </div>

                <div class="form-inline mt-5" style="margin-bottom: 16px;">
                    <label for="start" class="form-label sm:w-40">Verleih-Datum</label>
                    <VueDatePicker
                        format="dd.MM.yyyy"
                        locale="de"
                        cancelText="abbrechen"
                        selectText="auswählen"
                        v-model="form.start"
                        :auto-apply="true"
                        class="form-control"
                        id="start"
                        autocomplete="off"
                        style="max-width: 440px;"
                    />
                </div>

                <div class="form-inline" style="margin-bottom: 16px;">
                    <label for="horizontal-form-1" class="form-label sm:w-40">Instrument</label>
                    <VueMultiselect
                        :model-value="form.instrument"
                        :options="instruments"
                        :searchable="true"
                        :close-on-select="true"
                        :clear-on-select="false"
                        :allow-empty="false"
                        @update:model-value="onInstrumentSelected"
                        label="sku"
                        placeholder="Bitte Instrument wählen"
                        track-by="id"
                        style="width:440px;max-width:440px;font-size: 14px;"
                        class="form-control"
                    />
                </div>

                <div class="form-inline" style="margin-bottom: 16px;">
                    <label for="horizontal-form-1" class="form-label sm:w-40">Schüler</label>
                    <VueMultiselect
                        :model-value="form.pupil"
                        :options="pupils"
                        :searchable="true"
                        :close-on-select="true"
                        :clear-on-select="false"
                        :allow-empty="false"
                        @update:model-value="onPupilSelected"
                        label="pupil_name"
                        :custom-label="customLabel"
                        placeholder="Bitte Schüler wählen"
                        track-by="id"
                        style="width:440px;max-width:440px;font-size: 14px;"
                    />
                </div>
            </div>
        </div>

        <div class="grid grid-cols-1">
            <div class="px-5 pt-8 pb-8 text-center">
                <button
                    type="button"
                    @click="onClose"
                    class="btn btn-outline-warning w-24 mr-1"
                >
                    abbrechen
                </button>
                <button
                    type="button"
                    class="btn btn-success w-24"
                    @click="onSave"
                >
                    speichern
                </button>
            </div>
        </div>

    </div>
</template>

<script>
import axios from "axios";
import VueMultiselect from 'vue-multiselect';
import VueTimepicker from "vue3-timepicker";
import {Tab} from "../global-components/tab";
import FormErrorBox from "./FormErrorBox.vue";
import authHeader from "../services/auth-header";

export default {
    name: "EditRentalForm",

    components: {
        Tab,
        FormErrorBox,
        VueMultiselect,
        VueTimepicker
    },

    props: {
        rental: {
            type: Object,
            required: false,
            default: {}
        }
    },

    data() {
        return {
            loaded      : false,
            instruments : [],
            pupils      : [],
            form        : {},
        }
    },

    mounted() {
        this.form = JSON.parse(JSON.stringify(this.rental));

        if (this.form.start == null || this.form.start === '') {
            let options = { year: "numeric", month: "2-digit", day: "2-digit" };
            this.form.start = new Date().toLocaleString('en-US', options);
        }

        axios
            .get(this.$store.state.config.apiUrl + '/pupilsSimple', { headers: authHeader() })
            .then(response => {
                this.pupils = response.data.data;
            });

        axios
            .get(this.$store.state.config.apiUrl + '/instrumentsSimple', { headers: authHeader() })
            .then(response => {
                this.instruments = response.data.data;
                this.loaded = true;
            });
    },

    methods: {

        customLabel(pupil) {
            return pupil.pupil_number + ', ' + pupil.pupil_name;
        },

        onPupilSelected: function(pupil) {
            this.form.pupil = pupil;
            this.form.pupil_id = pupil.id;
        },

        onInstrumentSelected: function(instrument) {
            this.form.instrument = instrument;
            this.form.instrument_id = instrument.id;
        },

        //---- Remote API Calls -----//
        onSave() {
            $('#rental-form .text-danger').html('');
            $('#rental-form input.border-danger').removeClass('border-danger');

            if (this.form.start != null && this.form.start !== '') {
                let options = { year: "numeric", month: "2-digit", day: "2-digit" };
                this.form.start = this.form.start.toLocaleString('en-US', options);
            }

            if (this.form.id == null || this.form.id < 1) {
                this.create();
            } else {
                this.update();
            }
        },

        update() {
            axios
                .put(this.$store.state.config.apiUrl + '/renting/' + this.form.id, this.form, { headers: authHeader() })
                .then(response => {
                    this.$emit('save');

                    this.showNotification(true, 'Speichern', 'Die Daten wurden gespeichert');
                })
                .catch(response => {
                    this.showFormErrors(response.response.data.errors);
                });
        },

        create() {
            axios
                .post(this.$store.state.config.apiUrl + '/renting', this.form, { headers: authHeader() })
                .then(response => {
                    this.$emit('save');

                    window.open('/api/v1/makeRentingDocument/'+response.data.item.id+'/'+this.$store.state.auth.accessToken, '_blank');

                    this.showNotification(true, 'Speichern', 'Die Daten wurden gespeichert');
                })
                .catch(response => {
                    this.showFormErrors(response.response.data.errors);
                });
        },

        onClose() {
            for (let x = 0; x < this.form.length; x++) {
                this.form[x] = '';
            }
            this.$emit('close');
        },

        showFormErrors(error) {
            _.each(error, (message, key) => {
                key = key.replace('order.', '');
                $('#rental-form #' + key).addClass('border-danger');
                let box = $('#rental-form .text-danger');
                if (box != null) {
                    box.append('<p>' + message[0] + '</p>');
                }
            });
            $('.modal').animate({ scrollTop: 0}, 'fast');
        }
    }
}
</script>

<style>

</style>
