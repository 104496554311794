
const initialState = {
    apiUrl: '/api/v1',
    webUrl: '/',
    notification : false,
};

export const config = {
    namespaced  : true,
    state       : initialState,

    actions: {
        setNotification(not) {
            this.notification = not;
        }
    },

    getters: {
        notification(state, getters) {
            return state.notification;
        },
        apiUrl(state, getters) {
            return state.apiUrl;
        },
        webUrl(state, getters) {
            return state.webUrl;
        }
    },

    mutations: {
    }
};
