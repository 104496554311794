<template>
    <div>
        <DarkModeSwitcher/>
        <div class="container sm:px-10">
            <div class="block xl:grid grid-cols-2 gap-4">
                <!-- BEGIN: Login Info -->
                <div class="hidden xl:flex flex-col min-h-screen">
                    <a href="" class="-intro-x flex items-center pt-5">
                        <img
                            alt="Midone Tailwind HTML Admin Template"
                            class="w-6"
                            src="@/assets/images/logo.svg"
                        />
                        <span class="text-white text-lg ml-3"> Köller-IT Services & Consulting </span>
                    </a>
                    <div class="my-auto">
                        <img
                            alt="Midone Tailwind HTML Admin Template"
                            class="-intro-x w-1/2 -mt-16"
                            src="@/assets/images/illustration.svg"
                        />
                        <div
                            class="-intro-x text-white font-medium text-4xl leading-tight mt-10"
                        >
                            Musikalien-Verleih
                        </div>
                    </div>
                </div>
                <!-- END: Login Info -->
                <!-- BEGIN: Login Form -->
                <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
                    <div
                        class="my-auto mx-auto xl:ml-20 bg-white dark:bg-darkmode-600 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
                    >
                        <h2
                            class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
                        >
                            Anmelden
                        </h2>
                        <div v-if="error" class="intro-x mt-2 text-error text-center">
                            Bitte überprüfen Sie Ihren Benutzernamen und das Passwort.
                        </div>

                        <form @submit.prevent="doLogin()">
                            <div class="intro-x mt-8">
                                <input
                                    type="text"
                                    class="intro-x login__input form-control py-3 px-4 block"
                                    placeholder="Email"
                                    autocomplete="off"
                                    v-model="form.email"
                                />
                                <input
                                    type="password"
                                    class="intro-x login__input form-control py-3 px-4 block mt-4"
                                    placeholder="Password"
                                    autocomplete="current-password"
                                    v-model="form.password"
                                />
                            </div>
                            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
                                <button
                                    class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
                                >
                                    Login
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- END: Login Form -->
            </div>
        </div>
    </div>
</template>

<script>
import DarkModeSwitcher from "../../components/dark-mode-switcher/Main.vue";
import dom from "@left4code/tw-starter/dist/js/dom";

export default {
    name: 'Login',
    components: {
        DarkModeSwitcher
    },
    data() {
        return {
            form:{
                email: '',
                password: ''
            },
            error: false
        }
    },

    mounted() {
        dom("body")
            .removeClass("main")
            .removeClass("error-page")
            .addClass("login");
    },

    methods: {

        doLogin: function() {
            this.error = false;
            this.$store.dispatch('auth/login', this.form)
                .then(response => {
                    this.$router.push('/');
                })
                .catch(error => {
                    this.error = true;
                });
        }
    }
}
</script>

<style scoped>
.text-error {
    font-size: 16px;
    color: #ff0000;
}
.dark .text-error {
    color: #FF007B;
}
</style>
