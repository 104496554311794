import {useNotificationStore} from "../stores/notification";
import dom from "@left4code/tw-starter/dist/js/dom";
import {createIcons, icons} from "lucide";
import dayjs from "dayjs";

export default {

    methods: {

        userHasRole: function(roles) {
            if (roles == null || roles.length < 1) {
                return true;
            }

            let found = false;
            let user = this.$store.getters['auth/user'];

            if (user == null) {
                found = false;
            } else {
                roles.forEach((role) => {
                    if (user.roles.find(x => x.name === role) != null) {
                        found = true;
                    }
                })
            }

            return found;
        },

        reInitOnResizeWindow: function () {
            window.addEventListener("resize", () => {
                this.tabulator.redraw();
                this.updateIcons();
            });
        },

        dateFormat: function (date) {
            if (date == null) {
                return '-';
            }
            return dayjs(date).format('DD.MM.YYYY');
        },

        updateIcons: function () {
            setTimeout(() => {
                createIcons({
                    icons,
                    color: '#fff',
                    "stroke-width": 2,
                    nameAttr: "data-lucide",
                });
            }, 200);
        },

        enableScrolling: function () {
            dom("body").removeClass("overflow-y-hidden");
        },

        showNotification: function (visible, text, description) {

            if (this.notificationStore == null) {
                this.notificationStore = useNotificationStore();
            }

            this.notificationStore.setVisible(visible);
            this.notificationStore.setText(text);
            this.notificationStore.setDescription(description);
        }
    }
};
