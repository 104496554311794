import {defineStore} from "pinia";

export const useTopMenuStore = defineStore("topMenu", {
    state: () => ({
        menu: [
            {
                icon: "MusicIcon",
                pageName: "top-menu-instruments",
                title: "Instrumente",
                roles: []
            },
            {
                icon: "MusicIcon",
                pageName: "top-menu-archive",
                title: "Instrumenten Archiv",
                roles: ['admin']
            },
            {
                icon: "UserIcon",
                pageName: "top-menu-pupils",
                title: "Schüler",
                roles: []
            },
            {
                icon: "UserIcon",
                pageName: "top-menu-pupils-archive",
                title: "Schüler Archiv",
                roles: ['admin']
            },
            {
                icon: "ListOrderedIcon",
                pageName: "top-menu-categories",
                title: "Kategorien",
                roles: ['admin']
            },
            {
                icon: "TruckIcon",
                pageName: "top-menu-producer",
                title: "Hersteller",
                roles: ['admin']
            },
            {
                icon: "HelpCircleIcon",
                pageName: "top-menu-status",
                title: "Status",
                roles: ['admin']
            },
            {
                icon: "HelpCircleIcon",
                pageName: "top-menu-schools",
                title: "Schulen",
                roles: ['admin']
            },
            {
                icon: "HelpCircleIcon",
                pageName: "top-menu-export",
                title: "Export",
                roles: ['admin']
            },
        ],
    }),
});
