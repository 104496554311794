<template>
    <div class="intro box mt-5" id="instrument-form" v-if="this.loaded">
        <div class="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
            <h2 class="font-medium text-base mr-auto">Instrument bearbeiten / anlegen</h2>
        </div>

        <div class="grid grid-cols-12 gap-6 pl-5 pr-5" v-if="showExchangeForm">
            <div class="intro col-span-12 lg:col-span-12 pb-4">
                <button
                    type="button"
                    style="float:right"
                    class="btn btn-primary w-48 mt-2"
                    @click="onExchangeEnd"
                >
                    zurück
                </button>
                <div class="text-center mt-6 mb-4">
                    <h3 class="text-2xl dark:text-white">Instrumenten-Tausch</h3>
                </div>
                <ExchangeInstrumentForm :rental="this.rental" @save="onExchangeSaved" @close="onExchangeEnd"/>
            </div>
        </div>

        <div class="grid grid-cols-12 gap-6 pl-5 pr-5" v-if="showRentalForm">
            <div class="intro col-span-12 lg:col-span-12 pb-4">
                <button
                    type="button"
                    style="float:right"
                    class="btn btn-primary w-48 mt-2"
                    @click="onEditRentalEnd"
                >
                    zurück
                </button>
                <div class="text-center mt-6 mb-4">
                    <h3 class="text-2xl dark:text-white">Verleih-Rückgabe</h3>
                </div>
                <ReturnRentalForm :rental="this.rental" @save="onEditRentalSaved" @close="onEditRentalEnd"/>
            </div>
        </div>

        <div class="grid grid-cols-12 gap-6 pl-5 pr-5" v-if="showRepairForm">
            <div class="intro col-span-12 lg:col-span-12 pb-4">
                <button
                    type="button"
                    style="float:right"
                    class="btn btn-primary w-48 mt-2"
                    @click="onRepairClose"
                >
                    zurück
                </button>
                <div class="text-center mt-6 mb-4">
                    <h3 class="text-2xl dark:text-white">Reparatur anlegen</h3>
                </div>
                <RepairForm :instrument_id="this.form.id" :id="repair_id" @save="onRepairSaved" @close="onRepairClose"/>
            </div>
        </div>

        <div class="grid grid-cols-12 gap-6 pl-5 pr-5" v-if="showUploadForm">
            <div class="intro col-span-12 lg:col-span-12 pb-4">
                <button
                    type="button"
                    style="float:right"
                    class="btn btn-primary w-48 mt-2"
                    @click="filesUploadedDone"
                >
                    zurück
                </button>
                <div class="text-center mt-6 mb-4">
                    <h3 class="text-2xl dark:text-white">Datei hochladen</h3>
                </div>
                <Upload :id="this.form.id" :type="'instrumentDocument'" @upload-done="filesUploaded" @close="filesUploadedDone"/>
            </div>
        </div>

        <div class="grid grid-cols-12 gap-6 pl-5 pr-5" v-if="showPupilDetails">
            <div class="intro col-span-12 lg:col-span-12 pb-4">
                <ShowPupilForm :pupil="pupil" @close="onShowPupilDone" />
            </div>
        </div>

        <div v-if="!showUploadForm && !showRepairForm && !showRentalForm && !showPupilDetails && !showExchangeForm">

            <div class="grid grid-cols-12 gap-6 pl-0 pr-5">

                <FormErrorBox />

                <div class="intro col-span-12 lg:col-span-6 pb-4">
                    <div class="text-center mb-4">
                        <h2 class="text-2xl dark:text-white">Informationen</h2>
                    </div>

                    <div class="form-inline">
                        <label for="name" class="form-label sm:w-40">Name</label>
                        <input
                            id="name"
                            type="text"
                            class="form-control"
                            placeholder=""
                            v-model="form.name"
                        />
                    </div>
                    <div class="form-inline mt-5">
                        <label for="sku" class="form-label sm:w-40">SKU</label>
                        <input
                            id="sku"
                            type="text"
                            class="form-control"
                            placeholder=""
                            v-model="form.sku"
                        />
                    </div>
                    <div class="form-inline mt-5">
                        <label for="price" class="form-label sm:w-40">Preis</label>
                        <input
                            id="price"
                            type="text"
                            class="form-control"
                            placeholder=""
                            v-model="form.price"
                        />
                    </div>
                    <div class="form-inline mt-5">
                        <label for="serial_number" class="form-label sm:w-40">Seriennummer</label>
                        <input
                            id="serial_number"
                            type="text"
                            class="form-control"
                            v-model="form.serial_number"
                        />
                    </div>
                    <div class="form-inline mt-5" style="margin-bottom: 16px;">
                        <label for="bought_date" class="form-label sm:w-40">Kaufdatum</label>
                        <VueDatePicker
                            id="bought_date"
                            format="dd.MM.yyyy"
                            locale="de"
                            cancelText="abbrechen"
                            selectText="auswählen"
                            v-model="form.bought_date"
                            :auto-apply="true"
                            class="form-control"
                            autocomplete="off"
                            style="max-width: 440px;"
                        />
                    </div>
                    <div class="form-inline" style="margin-bottom: 16px;">
                        <label for="category" class="form-label sm:w-40">Kategorie</label>
                        <VueMultiselect
                            id="category"
                            :model-value="form.category"
                            :options="categories"
                            :searchable="true"
                            :close-on-select="true"
                            :clear-on-select="false"
                            :allow-empty="false"
                            @update:model-value="onCategorySelected"
                            label="name"
                            placeholder="Bitte Kategorie wählen"
                            track-by="id"
                            style="width: 70%;font-size: 14px;"
                        />
                    </div>
                    <div class="form-inline" style="margin-bottom: 16px;">
                        <label for="producer" class="form-label sm:w-40">Hersteller</label>
                        <VueMultiselect
                            id="producer"
                            :model-value="form.producer"
                            :options="producers"
                            :searchable="true"
                            :close-on-select="true"
                            :clear-on-select="false"
                            :allow-empty="false"
                            @update:model-value="onProducerSelected"
                            label="name"
                            placeholder="Bitte Hersteller wählen"
                            track-by="id"
                            style="width: 70%;font-size: 14px;"
                        />
                    </div>
                    <div class="form-inline" style="margin-bottom: 16px;">
                        <label for="size" class="form-label sm:w-40">Größe</label>
                        <select
                            class="form-select sm:mr-2"
                            id="size"
                            aria-label="Größe"
                            style="width: 70%;font-size: 14px;"
                            v-model="form.size"
                        >
                            <option :value="''">---</option>
                            <option value="1/16">1/16</option>
                            <option value="1/8">1/8</option>
                            <option value="1/4">1/4</option>
                            <option value="1/2">1/2</option>
                            <option value="3/4">3/4</option>
                            <option value="4/4">4/4</option>
                        </select>
                    </div>
                    <div class="form-inline" style="margin-bottom: 16px;">
                        <label for="status" class="form-label sm:w-40">Status</label>
                        <VueMultiselect
                            id="status"
                            :model-value="form.status"
                            :options="statuses"
                            :searchable="true"
                            :close-on-select="true"
                            :clear-on-select="false"
                            :allow-empty="false"
                            @update:model-value="onStatusSelected"
                            label="name"
                            placeholder="Bitte Status wählen"
                            track-by="id"
                            style="width: 70%;font-size: 14px;"
                        />
                    </div>
                    <div class="form-inline" style="margin-bottom: 16px;">
                        <label for="school" class="form-label sm:w-40">Schule</label>
                        <VueMultiselect
                            id="school"
                            :model-value="form.school"
                            :options="schools"
                            :searchable="true"
                            :close-on-select="true"
                            :clear-on-select="false"
                            :allow-empty="false"
                            @update:model-value="onSchoolSelected"
                            label="name"
                            placeholder="Bitte Hersteller wählen"
                            track-by="id"
                            style="width: 70%;font-size: 14px;"
                        />
                    </div>

                    <div class="form-inline mt-5">
                        <label for="comment" class="form-label sm:w-40">Kommentar</label>
                        <textarea
                            id="comment"
                            class="form-control"
                            v-model="form.comment"
                        ></textarea>
                    </div>
                </div>

                <div class="intro col-span-12 lg:col-span-6 pb-4">
                    <div class="text-center mb-4">
                        <h3 class="text-2xl dark:text-white">Reparaturen</h3>
                    </div>
                    <table class="table table-bordered table-condensed table-hover table-striped">
                        <thead>
                        <tr>
                            <th>Datum</th>
                            <th>Reparatur</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="!form.repairs || form.repairs.length < 1">
                            <td colspan="3">es liegen keine Daten vor</td>
                        </tr>
                        <tr v-for="repair in form.repairs" :key="repair.id">
                            <td>{{ dateFormat(repair.repair_date) }}</td>
                            <td>{{ repair.description }}</td>
                            <td>
                                <button
                                    v-if="userHasRole(['admin'])"
                                    type="button"
                                    @click="showRepairForm=true;repair_id=repair.id"
                                    class="btn btn-xs btn-primary mt-2 mr-4"
                                >
                                    <i data-lucide="wrench" class="w-4 h-4 mr-1 wrench"></i>
                                </button>
                                <button
                                    v-if="userHasRole(['admin'])"
                                    type="button"
                                    @click="showDeleteRepair=true; deleteRepairId=repair.id"
                                    class="btn btn-xs btn-danger mt-2"
                                >
                                    <i data-lucide="trash" class="w-4 h-4 mr-1 trash"></i>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <button
                        v-if="this.form.id > 0 && editable && userHasRole(['admin'])"
                        type="button"
                        @click="repair_id=0;showRepairForm=true"
                        class="btn btn-primary w-48 mt-2"
                    >
                        Reparatur anlegen
                    </button>

                    <div class="text-center mt-6 mb-4">
                        <h3 class="text-2xl dark:text-white">Historie</h3>
                    </div>
                    <table class="table table-bordered table-condensed table-hover table-striped">
                        <thead>
                        <tr>
                            <th>Von</th>
                            <th>Bis</th>
                            <th>Schüler</th>
                            <th>Kommentar</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="!form.rentings || form.rentings.length < 1">
                            <td colspan="5">es liegen keine Daten vor</td>
                        </tr>
                        <tr v-for="renting in form.rentings" :key="renting.id">
                            <td>{{ dateFormat(renting.start) }}</td>
                            <td>{{ dateFormat(renting.end) }}</td>
                            <td>
                                <a href="#" @click="showPupil(renting.pupil)" v-if="renting.pupil">
                                    {{ renting.pupil.pupil_name }}
                                </a>
                            </td>
                            <td>{{ renting.comment }}</td>
                            <td>
                                <a
                                    v-if="userHasRole(['admin']) && (renting.end == null || renting.end === '')"
                                    class="btn btn-xs btn-success flex items-center mb-2 mr-3 edit"
                                    href="javascript:;"
                                    title="bearbeiten"
                                    @click="onEditRenting(renting)"
                                >
                                    <i data-lucide="wrench" class="w-4 h-4 mr-1 edit"></i>
                                </a>
                                <a
                                    v-if="userHasRole(['admin']) && (renting.end == null || renting.end === '')"
                                    class="btn btn-xs btn-success flex items-center mb-2 mr-3 text-white exchange"
                                    href="javascript:;"
                                    title="Instrument tauschen"
                                    @click="onExchange(renting)"
                                >
                                    <i data-lucide="arrow-right-left" class="w-4 h-4 mr-1 exchange"></i>
                                </a>
                                <a
                                    v-if="userHasRole(['admin'])"
                                    class="btn btn-xs btn-danger flex items-center mr-3"
                                    href="javascript:;"
                                    title="löschen"
                                    @click="onDeleteRenting(renting)"
                                >
                                    <i data-lucide="trash" class="w-4 h-4 mr-1 trash"></i>
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <div class="text-center mt-6 mb-4">
                        <h3 class="text-2xl dark:text-white">Dateien</h3>
                    </div>
                    <table class="table table-bordered table-condensed table-hover table-striped">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>hochgeladen am</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="!form.documents || form.documents.length < 1">
                            <td colspan="3">es liegen keine Daten vor</td>
                        </tr>
                        <tr v-for="document in form.documents" :key="document.id">
                            <td><a target="_blank" :href="'/uploads/' + document.name">{{ document.name }}</a></td>
                            <td>{{ dateFormat(document.created_at) }}</td>
                            <td>
                                <a
                                    v-if="userHasRole(['admin'])"
                                    class="btn btn-xs btn-danger flex items-center mr-3"
                                    href="javascript:;"
                                    title="löschen"
                                    @click="onDeleteFile(document)"
                                >
                                    <i data-lucide="trash" class="w-4 h-4 mr-1 trash"></i>
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <button
                        v-if="this.form.id > 0 && editable && userHasRole(['admin'])"
                        type="button"
                        @click="onUpload"
                        class="btn btn-primary w-48 mt-2"
                    >
                        Datei hochladen
                    </button>
                </div>
            </div>

            <div class="grid grid-cols-1">
                <div class="px-5 pt-8 pb-8 text-center">
                    <button
                        type="button"
                        @click="onClose"
                        class="btn btn-outline-warning w-24 mr-1"
                    >
                        abbrechen
                    </button>
                    <button
                        v-if="editable && userHasRole(['admin'])"
                        type="button"
                        class="btn btn-success w-24"
                        @click="onSave"
                    >
                        speichern
                    </button>
                </div>
            </div>
        </div>

        <!-- BEGIN: Delete Confirmation Modal -->
        <Modal
            :backdrop="'static'"
            :show="deleteConfirmationModal"
            @hidden="onDeleteEnd()"
        >
            <ModalBody class="p-0">
                <div class="p-5 text-center">
                    <XCircleIcon class="w-16 h-16 text-danger mx-auto mt-3"/>
                    <div class="text-3xl mt-5">Löschen</div>
                    <div class="text-slate-500 mt-2">
                        Wollen Sie diesen Datensatz wirklich löschen?
                    </div>
                </div>
                <div class="px-5 pb-8 text-center">
                    <button
                        type="button"
                        @click="onDeleteEnd()"
                        class="btn btn-outline-secondary w-24 mr-1"
                    >
                        abbrechen
                    </button>
                    <button
                        type="button"
                        class="btn btn-danger w-24"
                        @click="doDelete()"
                    >
                        ja
                    </button>
                </div>
            </ModalBody>
        </Modal>
        <!-- END: Delete Confirmation Modal -->

        <!-- BEGIN: Delete Confirmation Modal -->
        <Modal
            :backdrop="'static'"
            :show="showDeleteRepair"
            @hidden="onDeleteRepairEnd()"
        >
            <ModalBody class="p-0">
                <div class="p-5 text-center">
                    <XCircleIcon class="w-16 h-16 text-danger mx-auto mt-3"/>
                    <div class="text-3xl mt-5">Löschen</div>
                    <div class="text-slate-500 mt-2">
                        Wollen Sie diesen Datensatz wirklich löschen?
                    </div>
                </div>
                <div class="px-5 pb-8 text-center">
                    <button
                        type="button"
                        @click="onDeleteRepairEnd()"
                        class="btn btn-outline-secondary w-24 mr-1"
                    >
                        abbrechen
                    </button>
                    <button
                        type="button"
                        class="btn btn-danger w-24"
                        @click="doDeleteRepair()"
                    >
                        ja
                    </button>
                </div>
            </ModalBody>
        </Modal>
        <!-- END: Delete Confirmation Modal -->

        <!-- BEGIN: Delete Confirmation Modal -->
        <Modal
            :backdrop="'static'"
            :show="deleteRentingConfirmationModal"
            @hidden="onDeleteRentingEnd()"
        >
            <ModalBody class="p-0">
                <div class="p-5 text-center">
                    <XCircleIcon class="w-16 h-16 text-danger mx-auto mt-3"/>
                    <div class="text-3xl mt-5">Löschen</div>
                    <div class="text-slate-500 mt-2">
                        Wollen Sie diesen Datensatz wirklich löschen?
                    </div>
                </div>
                <div class="px-5 pb-8 text-center">
                    <button
                        type="button"
                        @click="onDeleteRentingEnd()"
                        class="btn btn-outline-secondary w-24 mr-1"
                    >
                        abbrechen
                    </button>
                    <button
                        type="button"
                        class="btn btn-danger w-24"
                        @click="doDeleteRenting()"
                    >
                        ja
                    </button>
                </div>
            </ModalBody>
        </Modal>
        <!-- END: Delete Confirmation Modal -->

    </div>
</template>

<script>
import axios from "axios";
import VueMultiselect from 'vue-multiselect';
import VueTimepicker from "vue3-timepicker";
import {Tab} from "../global-components/tab";
import Upload from "./Upload.vue";
import RepairForm from "./RepairForm.vue";
import ReturnRentalForm from "./ReturnRentalForm.vue";
import ShowPupilForm from "./ShowPupilForm.vue";
import upload from "./Upload.vue";
import FormErrorBox from "./FormErrorBox.vue";
import authHeader from "../services/auth-header";
import ExchangeInstrumentForm from "./ExchangeInstrumentForm.vue";
import dayjs from "dayjs";

export default {
    name: "EditInstrumentForm",

    components: {
        ExchangeInstrumentForm,
        FormErrorBox,
        ShowPupilForm,
        ReturnRentalForm,
        RepairForm,
        Upload,
        Tab,
        VueMultiselect,
        VueTimepicker
    },

    props: {
        instrument: {
            type: Object,
            required: true,
            default: {}
        },
        editable: {
            type: Boolean,
            required: false,
            default: true
        }
    },

    data() {
        return {
            loaded: true,
            showUploadForm: false,
            showRepairForm: false,
            showRentalForm: false,
            showPupilDetails: false,
            deleteConfirmationModal: false,
            deleteModel: null,
            deleteRentingConfirmationModal: false,
            deleteRentingModel: null,
            showDeleteRepair: false,
            showExchangeForm: false,
            deleteRepairId: 0,
            pupil: null,
            rental: null,
            repair_id: null,
            categories: [],
            producers: [],
            schools: [],
            statuses: [],
            form: {}
        }
    },

    mounted() {
        this.form = JSON.parse(JSON.stringify(this.instrument));

        if (this.form.bought_date == null || this.form.bought_date === '') {
            let options = { year: "numeric", month: "2-digit", day: "2-digit" };
            this.form.bought_date = new Date().toLocaleString('en-US', options);
        }

        axios
            .get(this.$store.state.config.apiUrl + '/producerSimple', { headers: authHeader() })
            .then(response => {
                this.producers = response.data.data;
            });

        axios
            .get(this.$store.state.config.apiUrl + '/schoolsSimple', { headers: authHeader() })
            .then(response => {
                this.schools = response.data.data;
            });

        axios
            .get(this.$store.state.config.apiUrl + '/statusSimple', { headers: authHeader() })
            .then(response => {
                this.statuses = response.data.data;
            });

        axios
            .get(this.$store.state.config.apiUrl + '/categorySimple', { headers: authHeader() })
            .then(response => {
                this.categories = response.data.data;
                this.loaded = true;
                this.updateIcons();
            });
    },

    computed: {
        upload() {
            return upload
        },
    },

    methods: {

        onExchange(rental) {
            this.rental = rental;
            this.showExchangeForm = true;
        },

        onExchangeEnd() {
            this.showExchangeForm = false;
            this.rental = null;
            this.updateIcons();
        },

        onExchangeSaved(rentalID, instrument) {
            let idx = this.form.rentings.findIndex(x => x.id === rentalID);
            if (idx >= 0) {
                let ret = this.form.rentings[idx]
                ret.end = dayjs().format('YYYY-MM-DD');
                ret.comment = 'getauscht gegen ' + instrument;

                window.$('#tabulator-html-filter-go').trigger('click')
            }
            this.onExchangeEnd();
        },

        onEditRentalEnd(instrument) {
            this.showRentalForm = false;
            this.rental = null;
            this.updateIcons();

            console.log(instrument);
            this.$emit('update', instrument);
        },

        onEditRentalSaved(rental) {
            let idx = this.form.rentings.findIndex(x => x.id === rental.id);
            if (idx >= 0) {
                this.form.rentings[idx] = rental;
            } else {
                this.form.rentings.push(this.form);
            }
            this.onEditRentalEnd(rental.instrument);
        },

        onRepairClose() {
            this.showRepairForm = false;
            this.repair_id = null;
            this.updateIcons();
        },

        onRepairSaved(repair) {
            let idx = this.form.repairs.findIndex(x => x.id === repair.id);
            if (idx >= 0) {
                this.form.repairs[idx] = repair;
            } else {
                this.form.repairs.push(repair);
            }

            this.onRepairClose();
        },

        filesUploaded(file) {
            this.form.documents.push(file);
        },

        filesUploadedDone() {
            this.showUploadForm = false;
            this.updateIcons();
        },

        onDeleteEnd() {
            this.deleteConfirmationModal = false;
            this.deleteModel = null;
            this.enableScrolling();
        },

        onDeleteRepairEnd() {
            this.showDeleteRepair = false;
            this.deleteRepairId = 0;
            this.enableScrolling();
            this.updateIcons();
        },

        onDeleteFile(document) {
            this.deleteModel = document;
            this.deleteConfirmationModal = true;
        },

        doDeleteRepair() {
            axios
                .delete(this.$store.state.config.apiUrl + '/repair/' + this.deleteRepairId, { headers: authHeader() })
                .then(response => {
                    this.form.repairs = this.form.repairs.filter(x => x.id !== this.deleteRepairId);
                    this.deleteRepairId = 0;
                    this.showDeleteRepair = false;

                    this.showNotification(true, 'Löschen', 'Der Datensatz wurde gelöscht');
                })
                .catch(error => {
                    this.showNotification(true, 'Löschen', error.response.data.message);
                });
        },

        doDelete() {
            axios
                .delete(this.$store.state.config.apiUrl + '/document/' + this.deleteModel.id, { headers: authHeader() })
                .then(response => {
                    this.form.documents = this.form.documents.filter(x => x.id !== this.deleteModel.id);
                    this.deleteModel = null;
                    this.deleteConfirmationModal = false;

                    this.showNotification(true, 'Löschen', 'Der Datensatz wurde gelöscht');
                })
                .catch(error => {
                    this.showNotification(true, 'Löschen', error.response.data.message);
                });
        },

        onShowPupilDone() {
            this.showPupilDetails = false;
            this.pupil = null;
            this.updateIcons();
        },

        showPupil(pupil) {
            this.showPupilDetails = true;
            this.pupil = pupil;
        },

        onEditRenting(rental) {
            this.rental = rental;
            this.showRentalForm = true;
        },

        onUpload() {
            this.showUploadForm = true;
        },

        onProducerSelected: function (producer) {
            this.form.producer = producer;
            this.form.producer_id = producer.id;
        },

        onCategorySelected: function (category) {
            this.form.category = category;
            this.form.category_id = category.id;
        },

        onSchoolSelected: function(school) {
            this.form.school = school;
            this.form.school_id = school.id;
        },

        onStatusSelected: function(status) {
            this.form.status = status;
            this.form.status_id = status.id;
        },

        //---- Remote API Calls -----//
        onSave() {
            $('#instrument-form .text-danger').html('');
            $('#instrument-form input.border-danger').removeClass('border-danger');

            let options = { year: "numeric", month: "2-digit", day: "2-digit" };
            this.form.bought_date = this.form.bought_date.toLocaleString('en-US', options);

            if (this.form.id == null || this.form.id < 1) {
                this.create();
            } else {
                this.update();
            }
        },

        update() {
            axios
                .put(this.$store.state.config.apiUrl + '/instrument/' + this.form.id, this.form, { headers: authHeader() })
                .then(response => {
                    this.$emit('save', response.data.item);

                    this.showNotification(true, 'Speichern', 'Die Daten wurden gespeichert');
                })
                .catch(response => {
                    this.showFormErrors(response.response.data.errors);
                });
        },

        create() {
            axios
                .post(this.$store.state.config.apiUrl + '/instrument', this.form, { headers: authHeader() })
                .then(response => {
                    this.$emit('save', response.data.item);

                    this.showNotification(true, 'Speichern', 'Die Daten wurden gespeichert');
                })
                .catch(response => {
                    this.showFormErrors(response.response.data.errors);
                });
        },

        onClose() {
            this.$emit('close', this.form);

            for (let x = 0; x < this.form.length; x++) {
                this.form[x] = '';
            }
        },

        showFormErrors(error) {
            _.each(error, (message, key) => {
                key = key.replace('order.', '');
                $('#instrument-form #' + key).addClass('border-danger');
                let box = $('#instrument-form .text-danger');
                if (box != null) {
                    box.append('<p>' + message[0] + '</p>');
                }
            });
            $('.modal').animate({scrollTop: 0}, 'fast');
        },

        onDeleteRenting(renting) {
            this.deleteRentingModel = renting;
            this.deleteRentingConfirmationModal = true;
        },

        onDeleteRentingEnd() {
            this.deleteRentingConfirmationModal = false;
            this.deleteRentingModel = null;
            this.enableScrolling();
        },

        doDeleteRenting() {
            axios
                .delete(this.$store.state.config.apiUrl + '/renting/' + this.deleteRentingModel.id, { headers: authHeader() })
                .then(response => {
                    this.form.rentings = this.form.rentings.filter(x => x.id !== this.deleteRentingModel.id);
                    this.onDeleteRentingEnd();

                    this.showNotification(true, 'Löschen', 'Der Datensatz wurde gelöscht');
                })
                .catch(error => {
                    this.showNotification(true, 'Löschen', error.response.data.message);
                });
        },
    }
}
</script>

<style>

</style>
