<template>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro box p-5 mt-5">
        <h2 class="mr-auto mt-4 mb-8 text-2xl">Instrumente</h2>
        <div class="flex flex-col w-full">
            <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
                <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                    <label for="type" class="form-label mb-0 mr-2">Typ</label>
                    <select
                        class="form-select sm:mr-2"
                        id="type"
                        aria-label="Hersteller"
                        style="width: auto;"
                        v-model="instrument.type"
                    >
                        <option v-for="type in types" :value="type.id">
                            {{ type.name }}
                        </option>
                    </select>
                </div>
                <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                    <label for="status" class="form-label mb-0 mr-2">Status</label>
                    <select
                        class="form-select sm:mr-2"
                        id="status"
                        aria-label="Status"
                        style="width: auto;"
                        v-model="instrument.status"
                    >
                        <option v-for="status in statuses" :value="status.id">
                            {{ status.name }}
                        </option>
                    </select>
                </div>
                <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                    <label for="size" class="form-label mb-0 mr-2">Größe</label>
                    <select
                        class="form-select sm:mr-2"
                        id="size"
                        aria-label="Größe"
                        style="width: auto;"
                        v-model="instrument.size"
                    >
                        <option value="">---</option>
                        <option value="1/16">1/16</option>
                        <option value="1/8">1/8</option>
                        <option value="1/4">1/4</option>
                        <option value="1/2">1/2</option>
                        <option value="3/4">3/4</option>
                        <option value="4/4">4/4</option>
                    </select>
                </div>
                <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                    <label for="rented" class="form-check-label mb-0 mr-2">nur freie Instrumente</label>
                    <input type="checkbox" id="not_rented" name="not_rented" value="1" v-model="instrument.not_rented" class="form-check-input" />
                </div>
                <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                    <label for="rented" class="form-check-label mb-0 mr-2">nur verliehene Instrumente</label>
                    <input type="checkbox" id="rented" name="rented" value="1" v-model="instrument.rented" class="form-check-input" />
                </div>

                <div class="mt-2 xl:mt-0">
                    <a
                        class="btn btn-primary sm:w-48 mt-2 sm:mt-0 sm:ml-1"
                        :href="'/api/v1/instruments/export/'+this.$store.state.auth.accessToken + instrumentsArgs"
                        title="Schaden melden"
                        target="_blank"
                    >
                        Export herunterladen
                    </a>
                </div>
            </form>
        </div>

        <div class="mt-8 mb-8 w-full">&nbsp;</div>

        <h2 class="mr-auto mt-8 mb-4 text-2xl">Schüler</h2>
        <div class="flex flex-col w-full">
            <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">

                <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                    <label for="rented" class="form-check-label mb-0 mr-2">mit aktuellem Verleih</label>
                    <input type="checkbox" id="rented" name="rented" value="1" v-model="pupil.rented" class="form-check-input" />
                </div>

                <div class="mt-2 xl:mt-0">
                    <a
                        class="btn btn-primary sm:w-48 mt-2 sm:mt-0 sm:ml-1"
                        :href="'/api/v1/pupils/export/'+this.$store.state.auth.accessToken + pupilArgs"
                        title="Schaden melden"
                        target="_blank"
                    >
                        Export herunterladen
                    </a>
                </div>
            </form>
        </div>

    </div>
</template>

<script>
import axios from "axios";
import authHeader from "../../services/auth-header";

export default {
    name: "DataExport",

    components: {
    },

    data() {
        return {
            types       : [],
            statuses    : [],
            instrument  : {
                type: null,
                status: null,
                size: null,
                not_rented: null,
                rented: null,
            },
            pupil       : {
                not_rented: 0,
                rented: 0,
            }
        }
    },

    computed: {
        'instrumentsArgs': function() {
            return '?t=' + this.instrument.type + '&s=' + this.instrument.status + '&x=' + this.instrument.size + '&n=' + this.instrument.not_rented + '&r=' + this.instrument.rented;
        },
        'pupilArgs': function () {
            return  '?&r=' + this.pupil.rented;
        }
    },

    mounted() {
        axios
            .get(this.$store.state.config.apiUrl + '/categorySimple', { headers: authHeader() })
            .then(response => {
                this.types = response.data.data;
            });

        axios
            .get(this.$store.state.config.apiUrl + '/statusSimple', { headers: authHeader() })
            .then(response => {
                this.statuses = response.data.data;
            });
    }
}
</script>
