import {createStore} from "vuex";
import createPersistedState from "vuex-persistedstate";
import {auth} from "./auth";
import {config} from "./config";

const store = createStore({
    modules: {
        auth,
        config,
    },
    plugins: [createPersistedState()],
});

export default store;
