import { defineStore } from "pinia";

export const useDarkModeStore = defineStore("darkMode", {
    state: () => ({
        darkModeValue: false,
        darkModeTextValue: '',
        darkModeLongTextValue: '',
    }),
    getters: {
        darkMode(state) {
            return state.darkModeValue;
        },
        darkModeText(state) {
            return state.darkModeTextValue;
        },
        darkModeLongText(state) {
            return state.darkModeLongTextValue;
        }
    },
    actions: {
        setDarkMode(darkMode) {
            this.darkModeValue = darkMode;
        },
        setDarkModeText(darkMode) {
            this.darkModeTextValue = darkMode;
        },
        setDarkModeLongText(darkMode) {
            this.darkModeLongTextValue = darkMode;
        }
    },
});
