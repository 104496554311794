<template>
    <div class="intro box mt-5" id="exchange-form" v-if="this.loaded">

        <div class="grid grid-cols-12 gap-6 pl-0 pr-5">

            <FormErrorBox />

            <div class="intro col-span-12 lg:col-span-12 pb-4">
                <div class="form-inline mt-0 mb-4">
                    <label for="origin" class="form-label sm:w-40">Schüler</label>
                    <p v-html="customLabel(form.pupil)"></p>
                </div>

                <div class="form-inline mb-4">
                    <label for="origin" class="form-label sm:w-40">Instrument</label>
                    <p v-html="instruments.find(x => x.id === form.instrument_id).name"></p>
                </div>

                <div class="form-inline mt-5" style="margin-bottom: 16px;">
                    <label for="horizontal-form-1" class="form-label sm:w-40">Tausch-Instrument</label>
                    <VueMultiselect
                        :model-value="form.exchange_instrument"
                        :options="instruments"
                        :searchable="true"
                        :close-on-select="true"
                        :clear-on-select="false"
                        :allow-empty="false"
                        @update:model-value="onInstrumentSelected"
                        label="sku"
                        placeholder="Bitte Instrument für den Tausch wählen"
                        track-by="id"
                        style="width:440px;max-width:440px;font-size: 14px;"
                        class="form-control"
                    />
                </div>

            </div>
        </div>

        <div class="grid grid-cols-1">
            <div class="px-5 pt-8 pb-8 text-center">
                <button
                    type="button"
                    @click="onClose"
                    class="btn btn-outline-warning w-24 mr-1"
                >
                    abbrechen
                </button>
                <button
                    type="button"
                    class="btn btn-success w-24"
                    @click="onSave"
                >
                    speichern
                </button>
            </div>
        </div>

    </div>
</template>

<script>
import axios from "axios";
import VueMultiselect from 'vue-multiselect';
import {Tab} from "../global-components/tab";
import FormErrorBox from "./FormErrorBox.vue";
import authHeader from "../services/auth-header";

export default {
    name: "ExchangeInstrumentForm",

    components: {
        Tab,
        FormErrorBox,
        VueMultiselect,
    },

    props: {
        rental: {
            type: Object,
            required: false,
            default: {}
        }
    },

    data() {
        return {
            loaded      : false,
            instruments : [],
            pupils      : [],
            form        : {},
        }
    },

    beforeMount() {
        this.form = JSON.parse(JSON.stringify(this.rental));
        this.form.exchange_instrument = {};

        axios
            .get(this.$store.state.config.apiUrl + '/instrumentsSimple', { headers: authHeader() })
            .then(response => {
                this.instruments = response.data.data;
                this.loaded = true;
            });
    },

    methods: {

        customLabel(pupil) {
            return pupil.pupil_number + ', ' + pupil.pupil_name;
        },

        onInstrumentSelected: function(instrument) {
            this.form.exchange_instrument = instrument;
            this.form.exchange_instrument_id = instrument.id;
        },

        //---- Remote API Calls -----//
        onSave() {
            $('#exchange-form .text-danger').html('');
            $('#exchange-form input.border-danger').removeClass('border-danger');

            this.update();
        },

        update() {
            axios
                .put(this.$store.state.config.apiUrl + '/renting/' + this.form.id, this.form, { headers: authHeader() })
                .then(response => {
                    this.$emit('save', this.form.id, this.form.exchange_instrument.name);

                    window.open('/api/v1/makeExchangeDocument/'+response.data.item.id+'/'+this.form.id+'/'+this.$store.state.auth.accessToken, '_blank');

                    this.showNotification(true, 'Speichern', 'Die Daten wurden gespeichert');
                })
                .catch(response => {
                    this.showFormErrors(response.response.data.errors);
                });
        },

        onClose() {
            for (let x = 0; x < this.form.length; x++) {
                this.form[x] = '';
            }
            this.$emit('close');
        },

        showFormErrors(error) {
            _.each(error, (message, key) => {
                key = key.replace('order.', '');
                $('#exchange-form #' + key).addClass('border-danger');
                let box = $('#exchange-form .text-danger');
                if (box != null) {
                    box.append('<p>' + message[0] + '</p>');
                }
            });
            $('.modal').animate({ scrollTop: 0}, 'fast');
        }
    }
}
</script>

<style>

</style>
