import { defineStore } from "pinia";

export const useNotificationStore = defineStore("darkMode", {
    state: () => ({
        show: false,
        text: '',
        description: '',
    }),
    getters: {
        isVisible(state) {
            return state.show;
        },
        getText(state) {
            return state.text;
        },
        getDescription(state) {
            return state.description;
        }
    },
    actions: {
        setVisible(visible) {
            this.show = visible;
        },
        setText(text) {
            this.text = text;
        },
        setDescription(description) {
            this.description = description;
        }
    },
});
