<template>

    <!-- BEGIN: HTML Table Data -->
    <div class="intro box p-5 mt-5">
        <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
            <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto" @submit="onFilter">
                <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                    <label
                        class="w-32 flex-none xl:w-auto xl:flex-initial mr-2"
                    >
                    </label>
                    <input
                        id="tabulator-html-filter-value"
                        v-model="filter.value"
                        type="text"
                        class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
                        placeholder="Suchtext"
                    />
                </div>
                <div class="mt-2 xl:mt-0">
                    <button
                        id="tabulator-html-filter-go"
                        type="button"
                        class="btn btn-primary w-full sm:w-24"
                        @click="onFilter"
                    >
                        suche
                    </button>
                    <button
                        id="tabulator-html-filter-reset"
                        type="button"
                        class="btn btn-secondary w-full sm:w-24 mt-2 sm:mt-0 sm:ml-1"
                        @click="onResetFilter"
                    >
                        abbrechen
                    </button>
                </div>
            </form>
        </div>

        <div class="overflow-x-auto scrollbar-hidden">
            <div
                id="tabulator"
                ref="tableRef"
                class="mt-5 table-report table-report--tabulator"
            ></div>
        </div>
    </div>

    <!-- BEGIN: Undelete Confirmation Modal -->
    <Modal
        :backdrop="'static'"
        :show="undeleteConfirmationModal"
        @hidden="onUnDeleteEnd()"
    >
        <ModalBody class="p-0">
            <div class="p-5 text-center">
                <XCircleIcon class="w-16 h-16 text-danger mx-auto mt-3"/>
                <div class="text-3xl mt-5">Archivieren</div>
                <div class="text-slate-500 mt-2">
                    Wollen Sie diesen Datensatz wirklich aus dem Archiv zurückholen?
                </div>
            </div>
            <div class="px-5 pb-8 text-center">
                <button
                    type="button"
                    @click="onUnDeleteEnd()"
                    class="btn btn-outline-secondary w-24 mr-1"
                >
                    abbrechen
                </button>
                <button
                    type="button"
                    class="btn btn-danger w-24"
                    @click="doUnDelete()"
                >
                    zurückholen
                </button>
            </div>
        </ModalBody>
    </Modal>
    <!-- END: Undelete Confirmation Modal -->
</template>

<script>
import axios from "axios";
import dom from "@left4code/tw-starter/dist/js/dom";
import Tabulator from "tabulator-tables";
import authHeader from "../../services/auth-header";

export default {
    name: "SchoolArchive",

    components: {
    },

    data() {
        return {
            undeleteConfirmationModal: false,
            undeleteModel   : null,
            tabulator       : null,
            filter      : {
                field: '*',
                type: 'like',
                value: ''
            }
        }
    },

    mounted() {
        this.initTabulator();
        this.reInitOnResizeWindow();
    },

    methods: {

        onUnDeleteEnd() {
            this.undeleteConfirmationModal = false;
            this.undeleteModel = null;
            this.enableScrolling();
        },

        onFilter(ev) {
            if (ev != null) {
                ev.preventDefault();
                ev.stopPropagation();
            }
            this.tabulator.setFilter(this.filter.field, this.filter.type, this.filter.value);
        },

        onResetFilter(ev) {
            this.filter.field = "name";
            this.filter.type = "like";
            this.filter.value = "";
            this.onFilter(ev);
        },

        initTabulator() {
            let _self = this;
            this.tabulator = new Tabulator(this.$refs.tableRef, {
                ajaxURL: this.$store.state.config.apiUrl + '/school?deleted=1',
                ajaxFiltering: true,
                ajaxSorting: true,
                ajaxConfig: {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json; charset=utf-8', //set specific content type
                        'Authorization': 'Bearer ' + this.$store.state.auth.accessToken
                    }
                },
                ajaxError: function(response) {
                  if (401 === response.status) {
                    _self.$store.dispatch('auth/logout', this.$store.state.auth.user);
                    _self.$router.push('/login');
                  } else {
                    return Promise.reject(response);
                  }
                },
                printAsHtml: true,
                printStyled: true,
                pagination: "remote",
                paginationSize: 15,
                paginationSizeSelector: [10, 15, 25, 50],
                layout: "fitColumns",
                responsiveLayout: "collapse",
                placeholder: "Es wurden keine Ergebnisse gefunden",
                columns: [
                    {
                        formatter: "responsiveCollapse",
                        width: 40,
                        minWidth: 30,
                        hozAlign: "center",
                        resizable: false,
                        headerSort: false,
                    },
                    {
                        title: "Name",
                        minWidth: 120,
                        field: "name",
                        vertAlign: "middle",
                        print: false,
                        download: false
                    },
                    {
                        title: "erstellt am",
                        field: "created_at",
                        hozAlign: "center",
                        vertAlign: "middle",
                        formatter: "datetime",
                        formatterParams: {
                            outputFormat: "DD.MM.YYYY",
                            invalidPlaceholder: "-",
                            timezone: "Europe/Berlin",
                        }
                    },
                    {
                        title: "",
                        minWidth: 60,
                        hozAlign: "center",
                        vertAlign: "middle",
                        headerSort: false,
                        print: false,
                        download: false,
                        formatter(cell) {

                            const a = dom(`<div class="flex justify-center items-center">
                                <a
                                    class="btn btn-xs btn-success flex items-center mr-3 trash"
                                    href="javascript:;"
                                    title="aus dem Archiv holen"
                                >
                                    <i data-lucide="trash" class="w-4 h-4 mr-1 trash"></i>
                                </a>
                            </div>`);

                            dom(a, 'a').on("click", function (ev) {
                                let status = cell.getData();
                                let elem = null;

                                if (ev.target.nodeName !== 'svg' || ev.target.nodeName !== 'a') {
                                    elem = $(ev.target).parent();
                                } else {
                                    elem = $(ev.target);
                                }

                                if (elem.hasClass('trash')) {
                                    _self.onUnDelete(status);
                                }
                            });

                            return a[0];
                        }
                    }
                ],
                renderComplete() {
                    _self.updateIcons();
                },
            });
        },

        onUnDelete(status) {
            this.undeleteConfirmationModal = true;
            this.undeleteModel = status;
        },

        doUnDelete() {
            this.undeleteModel.undelete = 1;

            axios
                .put(this.$store.state.config.apiUrl + '/school/' + this.undeleteModel.id, this.undeleteModel,{ headers: authHeader() })
                .then(response => {
                    this.tabulator.deleteRow(this.undeleteModel.id);
                    this.undeleteModel = null;
                    this.undeleteConfirmationModal = false;

                    this.showNotification(true, 'Archivieren', 'Der Datensatz wurde aus dem Archiv entfernt');
                })
                .catch(error => {
                    this.showNotification(true, 'Archivieren', error.response.data.message);
                });
        }
    }
}
</script>
