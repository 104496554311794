<template>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro box p-5">
        <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">

            <div class="flex flex-col w-100 items-end sm:mr-4 mt-2 xl:mt-0">
                <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto" @submit="onFilter">
                    <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                        <label
                            class="w-32 flex-none xl:w-auto xl:flex-initial mr-2"
                        >
                        </label>
                        <input
                            id="tabulator-html-filter-value"
                            v-model="filter.value"
                            type="text"
                            class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
                            placeholder="Suchtext"
                        />
                    </div>
                    <div class="mt-2 xl:mt-0">
                        <button
                            id="tabulator-html-filter-go"
                            type="button"
                            class="btn btn-primary w-full sm:w-24"
                            @click="onFilter"
                        >
                            suche
                        </button>
                        <button
                            id="tabulator-html-filter-reset"
                            type="button"
                            class="btn btn-secondary w-full sm:w-24 mt-2 sm:mt-0 sm:ml-1"
                            @click="onResetFilter"
                        >
                            abbrechen
                        </button>
                    </div>
                </form>
            </div>
        </div>

        <div class="overflow-x-auto scrollbar-hidden">
            <div
                id="tabulator"
                ref="tableRef"
                class="mt-5 table-report table-report--tabulator"
            ></div>
        </div>
    </div>

    <!-- BEGIN: Delete Confirmation Modal -->
    <Modal
        :backdrop="'static'"
        :show="deleteConfirmationModal"
        @hidden="deleteConfirmationModal = false; deleteModel = null; enableScrolling();"
    >
        <ModalBody class="p-0">
            <div class="p-5 text-center">
                <XCircleIcon class="w-16 h-16 text-danger mx-auto mt-3"/>
                <div class="text-3xl mt-5">Archiv</div>
                <div class="text-slate-500 mt-2">
                    Wollen Sie diesen Datensatz wirklich aus dem Archiv zurückholen?
                </div>
            </div>
            <div class="px-5 pb-8 text-center">
                <button
                    type="button"
                    @click="deleteConfirmationModal = false; deleteModel = null; enableScrolling()"
                    class="btn btn-outline-secondary w-24 mr-1"
                >
                    abbrechen
                </button>
                <button
                    type="button"
                    class="btn btn-danger w-24"
                    @click="doUnDelete()"
                >
                    zurückholen
                </button>
            </div>
        </ModalBody>
    </Modal>
    <!-- END: Delete Confirmation Modal -->
</template>

<script>
import axios from "axios";
import dom from "@left4code/tw-starter/dist/js/dom";
import EditPupilForm from '../../partials/EditPupilForm';
import Tabulator from "tabulator-tables";
import authHeader from "../../services/auth-header";

export default {
    name: "PupilsArchive",

    components: {
        EditPupilForm,
    },

    data() {
        return {
            editPupilModal: false,
            editPupil: null,
            deleteConfirmationModal: false,
            deleteModel : null,
            tabulator: null,
            filter: {
                field: 'name',
                type: '=',
                value: '',
                producer: ''
            }
        }
    },

    mounted() {
        this.initTabulator();
        this.reInitOnResizeWindow();
    },

    methods: {

        onFilter(ev) {
            if (ev != null) {
                ev.preventDefault();
                ev.stopPropagation();
            }
            this.tabulator.setFilter(this.filter.field, this.filter.type, this.filter.value);
        },

        onResetFilter(ev) {
            this.filter.field = "name";
            this.filter.type = "like";
            this.filter.value = "";
            this.tabulator.clearFilter();
            this.onFilter(ev);
        },

        onUnDelete(pupil) {
            this.deleteConfirmationModal = true;
            this.deleteModel = pupil;
        },

        doUnDelete() {
            this.deleteModel.undelete = 1;

            axios
                .put(this.$store.state.config.apiUrl + '/pupil/' + this.deleteModel.id, this.deleteModel, { headers: authHeader() })
                .then(response => {
                    this.tabulator.deleteRow(this.deleteModel.id);
                    this.deleteModel = null;
                    this.deleteConfirmationModal = false;

                    this.showNotification(true, 'Archivieren', 'Der Datensatz wurde aus dem Archiv entfernt');
                })
                .catch(error => {
                    this.showNotification(true, 'Archivieren', error.response.data.message);
                });
        },

        initTabulator() {
            let _self = this;
            this.tabulator = new Tabulator(this.$refs.tableRef, {
                ajaxURL: this.$store.state.config.apiUrl + '/pupil?deleted=1',
                ajaxFiltering: true,
                ajaxSorting: true,
                ajaxConfig: {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json; charset=utf-8', //set specific content type
                        'Authorization': 'Bearer ' + this.$store.state.auth.accessToken
                    }
                },
                ajaxError: function(response) {
                    if (401 === response.status) {
                        _self.$store.dispatch('auth/logout', this.$store.state.auth.user);
                        _self.$router.push('/login');
                    } else {
                        return Promise.reject(response);
                    }
                },
                printAsHtml: true,
                printStyled: true,
                pagination: "remote",
                paginationSize: 15,
                paginationSizeSelector: [10, 15, 25, 50],
                layout: "fitColumns",
                responsiveLayout: "collapse",
                placeholder: "Es wurden keine Ergebnisse gefunden",
                rowClick: function (ev, rowComponent) {
                },
                columns: [
                    {
                        title: "Nummer",
                        headerTooltip: 'Nummer',
                        field: "pupil_number",
                        vertAlign: "middle",
                        responsive: 0
                    },
                    {
                        title: "Name",
                        headerTooltip: 'Name',
                        field: "firstname",
                        vertAlign: "middle",
                        responsive: 0,
                        formatter: function (cell, formatterParams, onRendered) {
                            let order = cell.getData();
                            return order.firstname + ' ' + order.lastname;
                        }
                    },
                    {
                        title: "Adresse",
                        headerTooltip: 'Adresse',
                        field: "street",
                        vertAlign: "middle",
                        responsive: 0,
                        formatter: function (cell, formatterParams, onRendered) {
                            let pupil = cell.getData();
                            if (pupil.street == null || pupil.zip == null) return '';
                            return pupil.street + ', ' + pupil.zip + ' ' + pupil.city;
                        }
                    },
                    {
                        title: "Schüler",
                        headerTooltip: 'Schüler',
                        field: "pupil_name",
                        vertAlign: "middle",
                    },
                    {
                        title: "Schule",
                        headerTooltip: 'Schule',
                        field: "school",
                        vertAlign: "middle",
                    },
                    {
                        title: "Instrument",
                        headerTooltip: 'Instrument',
                        field: "rentings",
                        vertAlign: "middle",
                        formatter: function (cell, formatterParams, onRendered) {
                            let pupil = cell.getData();
                            if (pupil.rentings != null && pupil.rentings.length > 0) {
                                let res = pupil.rentings.filter(x => x.end == null);
                                if (res.length > 0) {
                                    return res[0].instrument.sku;
                                }
                            }
                        }
                    },
                    {
                        title: "",
                        minWidth: 60,
                        hozAlign: "center",
                        vertAlign: "middle",
                        headerSort: false,
                        print: false,
                        download: false,
                        formatter(cell) {

                            const a = dom(`<div class="flex justify-center items-center">
                                  <a
                                      class="btn btn-xs btn-warning flex items-center mr-3 rotate-ccw"
                                      href="javascript:;"
                                      title="aus dem Archiv zurückholen"
                                  >
                                      <i data-lucide="rotate-ccw" class="w-4 h-4 mr-1 rotate-ccw"></i>
                                  </a>
                              </div>`);

                            dom(a, 'a').on("click", function (ev) {
                                let pupil = cell.getData();
                                let elem = null;

                                if (ev.target.nodeName !== 'svg' || ev.target.nodeName !== 'a') {
                                    elem = $(ev.target).parent();
                                } else {
                                    elem = $(ev.target);
                                }

                                if (elem.hasClass('rotate-ccw')) {
                                    _self.onUnDelete(pupil);
                                }
                            });

                            return a[0];
                        }
                    }
                ],
                renderComplete() {
                    _self.updateIcons();
                },
            });
        },
    }
}
</script>
