<template>
    <div class="intro box mt-5" id="pupil-form" v-if="this.loaded">
        <div class="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
            <h2 class="font-medium text-base mr-auto">Schüler {{ form.pupil_name }}</h2>
        </div>

        <div class="grid grid-cols-12 gap-6 pl-0 pr-5">

            <div class="intro col-span-12">
                <span class="w-full text-danger text-red"></span>
            </div>

            <div class="intro col-span-12 lg:col-span-6 pb-4">
                <div class="form-inline">
                    <label for="pupil_number" class="form-label sm:w-40">Nummer</label>
                    {{ form.pupil_number }}
                </div>
                <div class="form-inline mt-5">
                    <label for="pupil_name" class="form-label sm:w-40">Schüler</label>
                    {{ form.pupil_name }}
                </div>
                <div class="form-inline mt-5">
                    <label for="firstname" class="form-label sm:w-40">Vorname</label>
                        {{ form.firstname }}
                </div>
                <div class="form-inline mt-5">
                    <label for="lastname" class="form-label sm:w-40">Nachname</label>
                    {{ form.lastname }}
                </div>
                <div class="form-inline mt-5">
                    <label for="street" class="form-label sm:w-40">Straße</label>
                    {{ form.street }}
                </div>
                <div class="form-inline mt-5">
                    <label for="city" class="form-label sm:w-40">Stadt</label>
                    {{ form.city }}
                </div>
                <div class="form-inline mt-5">
                    <label for="zip" class="form-label sm:w-40">PLZ</label>
                    {{ form.zip }}
                </div>
            </div>

            <div class="intro col-span-12 lg:col-span-6 pb-4">
                <div class="form-inline mt-5">
                    <label for="email" class="form-label sm:w-40">E-Mail</label>
                    {{ form.email }}
                </div>
                <div class="form-inline mt-5">
                    <label for="phone" class="form-label sm:w-40">Telefon</label>
                    {{ form.phone }}
                </div>
                <div class="form-inline mt-5">
                    <label for="mobile" class="form-label sm:w-40">Handy</label>
                    {{ form.mobile }}
                </div>
                <div class="form-inline mt-5">
                    <label for="school" class="form-label sm:w-40">Schule</label>
                    {{ form.school }}
                </div>
                <div class="form-inline mt-5">
                    <label for="teacher" class="form-label sm:w-40">Lehrer</label>
                    {{ form.teacher }}
                </div>
                <div class="form-inline mt-5">
                    <label for="class" class="form-label sm:w-40">Klasse</label>
                    {{ form.class }}
                </div>
                <div class="form-inline mt-5">
                    <label for="class" class="form-label sm:w-40">Instrument</label>
                    {{ form.instrument }}
                </div>
            </div>
        </div>

        <div class="grid grid-cols-12 gap-6 pr-5 pl-5">
            <div class="intro col-span-12 lg:col-span-6 pb-4">
                <div class="text-center mt-6 mb-4">
                    <h3 class="text-2xl dark:text-white">Historie</h3>
                </div>
                <table class="table table-bordered table-condensed table-hover table-striped">
                    <thead>
                    <tr>
                        <th>Von</th>
                        <th>Bis</th>
                        <th>Instrument</th>
                        <th>Kommentar</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-if="!form.rentings || form.rentings.length < 1">
                            <td colspan="4">es liegen keine Daten vor</td>
                        </tr>
                        <tr v-for="renting in form.rentings" :key="renting.id">
                            <td>{{ dateFormat(renting.start) }}</td>
                            <td>{{ dateFormat(renting.end) }}</td>
                            <td>{{ renting.instrument.name }}</td>
                            <td>{{ renting.comment }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="intro col-span-12 lg:col-span-6 pb-4">
                <div class="text-center mt-6 mb-4">
                    <h3 class="text-2xl dark:text-white">Dateien</h3>
                </div>
                <table class="table table-bordered table-condensed table-hover table-striped">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>hochgeladen am</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="!form.documents || form.documents.length < 1">
                            <td colspan="3">es liegen keine Daten vor</td>
                        </tr>
                        <tr v-for="document in form.documents" :key="document.id">
                            <td><a target="_blank" :href="'/uploads/' + document.name">{{ document.name }}</a></td>
                            <td>{{ dateFormat(document.created_at) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="grid grid-cols-1">
            <div class="px-5 pt-8 pb-8 text-center">
                <button
                    type="button"
                    @click="onClose"
                    class="btn btn-outline-warning w-24 mr-1"
                >
                    schließen
                </button>
            </div>
        </div>
    </div>

</template>

<script>
import axios from "axios";
import dayjs from 'dayjs';
import VueMultiselect from 'vue-multiselect';
import VueTimepicker from "vue3-timepicker";
import Upload from "./Upload.vue";
import authHeader from "../services/auth-header";

export default {
    name: "ShowPupilForm",

    components: {
        Upload,
        VueMultiselect,
        VueTimepicker
    },

    props: {
        pupil: {
            type: Object,
            required: true,
            default: {}
        }
    },

    data() {
        return {
            loaded          : false,
            form            : {}
        }
    },

    mounted() {
        this.form = this.pupil;

        axios
            .get(this.$store.state.config.apiUrl + '/pupil/' + this.form.id, { headers: authHeader() })
            .then(response => {
                this.form = response.data.item;
            });
    },

    watch: {
        form: function(newValue, oldValue) {
            this.form = newValue;
            this.loaded = true;
        }
    },

    methods: {

        onClose() {
            for (let x = 0; x < this.form.length; x++) {
                this.form[x] = '';
            }
            this.$emit('close');
        },
    }
}
</script>

<style>

</style>
