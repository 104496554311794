import {createRouter, createWebHistory} from "vue-router";
import TopMenu from "../layouts/top-menu/Main.vue";

import Login from "../views/login/Main";
import Pupils from "../views/pupils/Main.vue";
import PupilsArchive from "../views/pupils_archive/Main.vue";
import School from "../views/school/Main.vue";
import SchoolArchive from "../views/school_archive/Main.vue";
import Status from "../views/status/Main.vue";
import Producer from "../views/producer/Main.vue";
import Categories from "../views/categories/Main.vue";
import Instruments from "../views/instruments/Main.vue";
import InstrumentsArchive from "../views/instruments_archive/Main.vue";
import DataExport from "../views/data_export/Main.vue";

const routes = [
    {
        path: "/login",
        component: Login,
    },
    {
        path: "/",
        name: "Übersicht",
        component: TopMenu,
        children: [
            {
                path: "/",
                name: "top-menu-instruments",
                component: Instruments,
            },
            {
                path: "/archive",
                name: "top-menu-archive",
                component: InstrumentsArchive,
            },
            {
                path: "/pupils",
                name: "top-menu-pupils",
                component: Pupils,
            },
            {
                path: "/pupils-archive",
                name: "top-menu-pupils-archive",
                component: PupilsArchive,
            },
            {
                path: "/categories",
                name: "top-menu-categories",
                component: Categories,
            },
            {
                path: "/producer",
                name: "top-menu-producer",
                component: Producer,
            },
            {
                path: "/status",
                name: "top-menu-status",
                component: Status,
            },
            {
                path: "/schools",
                name: "top-menu-schools",
                component: School,
            },
            {
                path: "/schools-archive",
                name: "top-menu-schools-archive",
                component: SchoolArchive,
            },
            {
                path: "/export",
                name: "top-menu-export",
                component: DataExport,
            },
        ],
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return savedPosition || {left: 0, top: 0};
    },
});

router.beforeEach((to, from, next) => {
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = router.store.getters['auth/isLoggedIn'];

    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
        next('/login');
    } else {
        next();
    }
});

export default router;
