<template>
    <div class="intro box mt-5" id="instrument-form" v-if="this.loaded">
        <div class="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
            <h2 class="font-medium text-base mr-auto">Instrument </h2>
        </div>

        <div class="grid grid-cols-12 gap-6 pl-5 pr-5" v-if="showPupilDetails">
            <div class="intro col-span-12 lg:col-span-12 pb-4">
                <ShowPupilForm :pupil="pupil" @close="showPupilDetails=false;pupil=null;" />
            </div>
        </div>

        <div class="grid grid-cols-12 gap-6 pl-0 pr-5">

            <div class="intro col-span-12">
                <span class="w-full text-danger text-red"></span>
            </div>

            <div class="intro col-span-12 lg:col-span-6 pb-4">

                <div class="form-inline">
                    <label for="name" class="form-label sm:w-40">Name</label>
                    {{ form.name }}
                </div>
                <div class="form-inline mt-5">
                    <label for="sku" class="form-label sm:w-40">SKU</label>
                    {{ form.sku }}
                </div>
                <div class="form-inline mt-5">
                    <label for="price" class="form-label sm:w-40">Preis</label>
                    {{ form.price }}
                </div>
                <div class="form-inline mt-5">
                    <label for="serial_number" class="form-label sm:w-40">Seriennummer</label>
                    {{ form.serial_number }}
                </div>
                <div class="form-inline mt-5" style="margin-bottom: 16px;">
                    <label for="bought_date" class="form-label sm:w-40">Kaufdatum</label>
                    {{ dateFormat(form.bought_date) }}
                </div>
                <div class="form-inline" style="margin-bottom: 16px;">
                    <label for="horizontal-form-1" class="form-label sm:w-40">Kategorie</label>
                    {{ form.category.name || '' }}
                </div>
                <div class="form-inline" style="margin-bottom: 16px;">
                    <label for="horizontal-form-1" class="form-label sm:w-40">Hersteller</label>
                    {{ form.producer.name || '' }}
                </div>
                <div class="form-inline mt-5">
                    <label for="comment" class="form-label sm:w-40">Kommentar</label>
                    {{ form.comment }}
                </div>
            </div>

            <div class="intro col-span-12 lg:col-span-6 pb-4">
                <div class="text-center mb-4">
                    <h3 class="text-2xl dark:text-white">Reparaturen</h3>
                </div>
                <table class="table table-bordered table-condensed table-hover table-striped">
                    <thead>
                        <tr>
                            <th>Datum</th>
                            <th>Reparatur</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="!form.repairs || form.repairs.length < 1">
                            <td colspan="4">es liegen keine Daten vor</td>
                        </tr>
                        <tr v-for="repair in form.repairs" :key="repair.id">
                            <td>{{ dateFormat(repair.repair_date) }}</td>
                            <td>{{ repair.description }}</td>
                        </tr>
                    </tbody>
                </table>

                <div class="text-center mt-6 mb-4">
                    <h3 class="text-2xl dark:text-white">Historie</h3>
                </div>
                <table class="table table-bordered table-condensed table-hover table-striped">
                    <thead>
                        <tr>
                            <th>Von</th>
                            <th>Bis</th>
                            <th>Schüler</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="!form.rentings || form.rentings.length < 1">
                            <td colspan="3">es liegen keine Daten vor</td>
                        </tr>
                        <tr v-for="renting in form.rentings" :key="renting.id">
                            <td>{{ dateFormat(renting.start) }}</td>
                            <td>{{ dateFormat(renting.end) }}</td>
                            <td>
                                <a href="#" @click="showPupil(renting.pupil)" v-if="renting.pupil">
                                    {{ renting.pupil.pupil_name }}
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="text-center mt-6 mb-4">
                    <h3 class="text-2xl dark:text-white">Dateien</h3>
                </div>
                <table class="table table-bordered table-condensed table-hover table-striped">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>hochgeladen am</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="!form.documents || form.documents.length < 1">
                            <td colspan="3">es liegen keine Daten vor</td>
                        </tr>
                        <tr v-for="document in form.documents" :key="document.id">
                            <td><a target="_blank" :href="'/uploads/' + document.name">{{ document.name }}</a></td>
                            <td>{{ dateFormat(document.created_at) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="grid grid-cols-1">
            <div class="px-5 pt-8 pb-8 text-center">
                <button
                    type="button"
                    @click="onClose"
                    class="btn btn-outline-warning w-24 mr-1"
                >
                    schließen
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import dayjs from 'dayjs';
import {createIcons, icons} from "lucide";
import VueMultiselect from 'vue-multiselect';
import VueTimepicker from "vue3-timepicker";
import {Tab} from "../global-components/tab";
import ShowPupilForm from "./ShowPupilForm.vue";
import authHeader from "../services/auth-header";

export default {
    name: "ShowInstrumentForm",

    components: {
        ShowPupilForm,
        Tab,
        VueMultiselect,
        VueTimepicker
    },

    props: {
        instrument: {
            type: Object,
            required: true,
            default: {}
        },
        editable: {
            type: Boolean,
            required: false,
            default: true
        }
    },

    data() {
        return {
            loaded: false,
            showPupilDetails: false,
            pupil: null,
            form: {}
        }
    },

    mounted() {
        this.form = this.instrument;

        axios
            .get(this.$store.state.config.apiUrl + '/instrument/' + this.form.id, { headers: authHeader() })
            .then(response => {
                this.form = response.data.item;
                this.loaded = true;
            });

        createIcons({
            icons,
            color: '#fff',
            "stroke-width": 2,
            nameAttr: "data-lucide",
        });
    },

    watch: {
        form: function (newValue, oldValue) {
            this.form = newValue;
        }
    },

    methods: {

        showPupil(pupil) {
            this.showPupilDetails = true;
            this.pupil = pupil;
        },

        onClose() {
            for (let x = 0; x < this.form.length; x++) {
                this.form[x] = '';
            }
            this.$emit('close');
        },
    }
}
</script>

<style>

</style>
