<template>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro box p-5">
        <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">

            <div class="flex flex-col w-2/3 items-end sm:mr-4 mt-2 xl:mt-0">
                <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto" @submit="onFilter">
                    <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                        <label
                            class="w-32 flex-none xl:w-auto xl:flex-initial mr-2"
                        >
                        </label>
                        <input
                            id="tabulator-html-filter-value"
                            v-model="filter.value"
                            type="text"
                            class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
                            placeholder="Suchtext"
                        />
                    </div>
                    <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                        <label for="rented" class="form-check-label mb-0 mr-2">nur Schüler mit Verleih</label>
                        <input type="checkbox" id="rented" name="rented" value="1" v-model="filter.rented" class="form-check-input" />
                    </div>
                    <div class="mt-2 xl:mt-0">
                        <button
                            id="tabulator-html-filter-go"
                            type="button"
                            class="btn btn-primary w-full sm:w-24"
                            @click="onFilter"
                        >
                            suche
                        </button>
                        <button
                            id="tabulator-html-filter-reset"
                            type="button"
                            class="btn btn-secondary w-full sm:w-24 mt-2 sm:mt-0 sm:ml-1"
                            @click="onResetFilter"
                        >
                            abbrechen
                        </button>
                    </div>
                </form>
            </div>

            <div class="flex flex-col w-1/3 items-end sm:mr-4 mt-2 xl:mt-0" v-if="userHasRole(['admin'])">
                <button
                    id="tabulator-html-filter-reset"
                    type="button"
                    class="btn btn-secondary mt-2 sm:mt-0 sm:ml-1"
                    @click="createPupil"
                >
                    neuen Schüler erfassen
                </button>
            </div>

        </div>

        <div class="overflow-x-auto scrollbar-hidden">
            <div
                id="tabulator"
                ref="tableRef"
                class="mt-5 table-report table-report--tabulator"
            />
        </div>
    </div>

    <!-- BEGIN: Edit rental Modal -->
    <Modal
        v-if="editPupilModal"
        :show="editPupilModal"
        :backdrop="'static'"
        :size="'modal-xxl'"
    >
        <ModalBody class="p-0">
            <EditPupilForm
                :pupil="editPupil"
                @save="onPupilEditEnd"
                @close="onPupilEditEnd"
                @hidden="onPupilEditEnd"
            />
        </ModalBody>
    </Modal>
    <!-- END: Edit rental Modal -->

    <!-- BEGIN: Delete Confirmation Modal -->
    <Modal
        :backdrop="'static'"
        :show="deleteConfirmationModal"
        @hidden="onDeleteEnd()"
    >
        <ModalBody class="p-0">
            <div class="p-5 text-center">
                <XCircleIcon class="w-16 h-16 text-danger mx-auto mt-3"/>
                <div class="text-3xl mt-5">Eintrag archivieren?</div>
                <div class="text-slate-500 mt-2">
                    Wollen Sie diesen Datensatz wirklich archivieren?
                </div>
            </div>
            <div class="px-5 pb-8 text-center">
                <button
                    type="button"
                    @click="onDeleteEnd()"
                    class="btn btn-outline-secondary w-24 mr-1"
                >
                    abbrechen
                </button>
                <button
                    type="button"
                    class="btn btn-danger w-24"
                    @click="doDelete()"
                >
                    ja
                </button>
            </div>
        </ModalBody>
    </Modal>
    <!-- END: Delete Confirmation Modal -->
</template>

<script>
import axios from "axios";
import dom from "@left4code/tw-starter/dist/js/dom";
import EditPupilForm from '../../partials/EditPupilForm';
import Tabulator from "tabulator-tables";
import authHeader from "../../services/auth-header";
import dayjs from "dayjs";

export default {
    name: "Pupils",

    components: {
        EditPupilForm,
    },

    data() {
        return {
            editPupilModal: false,
            editPupil: null,
            editRowIndex: null,
            deleteConfirmationModal: false,
            deleteModel: null,
            tabulator: null,
            filter: {
                field: 'name',
                type: '=',
                value: '',
                producer: '',
                rented: 0,
            }
        }
    },

    mounted() {
        this.initTabulator();
        this.reInitOnResizeWindow();
    },

    methods: {

        onDeleteEnd() {
            this.deleteConfirmationModal = false;
            this.deleteModel = null;
            this.enableScrolling();
        },

        onPupilEditEnd(updatedModel) {
            this.editPupilModal = false;
            this.editPupil = null;

            this.enableScrolling();

            if (updatedModel) {
                this.tabulator.updateOrAddRow(this.editRowIndex, updatedModel);
                this.editRowIndex = null;
            }
        },

        onFilter(ev) {
            if (ev != null) {
                ev.preventDefault();
                ev.stopPropagation();
            }

            this.tabulator.setFilter([
                { 'field': this.filter.field, 'type': this.filter.type, 'value': this.filter.value },
                { 'field': 'rented', 'type': this.filter.type, 'value': this.filter.rented },
            ]);
        },

        onResetFilter(ev) {
            this.filter.field = "name";
            this.filter.type = "like";
            this.filter.value = "";
            this.tabulator.clearFilter();
            this.onFilter(ev);
        },

        createPupil() {
            this.editPupilModal = true;
            this.editPupil = {};
        },

        onEdit(pupil, rowIndex) {
            this.editPupilModal = true;
            this.editPupil = pupil;
            this.editRowIndex = rowIndex;
        },

        onDelete(pupil) {
            this.deleteConfirmationModal = true;
            this.deleteModel = pupil;
        },

        doDelete() {
            axios
                .delete(this.$store.state.config.apiUrl + '/pupil/' + this.deleteModel.id, { headers: authHeader() })
                .then(response => {
                    this.tabulator.deleteRow(this.deleteModel.id);
                    this.deleteModel = null;
                    this.deleteConfirmationModal = false;

                    this.showNotification(true, 'Archivieren', 'Der Datensatz wurde archiviert');
                })
                .catch(error => {
                    this.showNotification(true, 'Archivieren', error.response.data.message);
                });
        },

        initTabulator() {
            let _self = this;
            this.tabulator = new Tabulator(this.$refs.tableRef, {
                ajaxURL: this.$store.state.config.apiUrl + '/pupil',
                ajaxFiltering: true,
                ajaxSorting: true,
                ajaxConfig: {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json; charset=utf-8', //set specific content type
                        'Authorization': 'Bearer ' + this.$store.state.auth.accessToken
                    }
                },
                ajaxError: function(response) {
                    if (401 === response.status) {
                        _self.$store.dispatch('auth/logout', this.$store.state.auth.user);
                        _self.$router.push('/login');
                    } else {
                        return Promise.reject(response);
                    }
                },
                printAsHtml: true,
                printStyled: true,
                pagination: "remote",
                paginationSize: 15,
                paginationSizeSelector: [10, 15, 25, 50],
                layout: "fitColumns",
                responsiveLayout: "collapse",
                placeholder: "Es wurden keine Ergebnisse gefunden",
                rowClick: function (ev, rowComponent) {
                },
                columns: [
                    {
                        title: "Nummer",
                        headerTooltip: 'Nummer',
                        field: "pupil_number",
                        vertAlign: "middle",
                        responsive: 0
                    },
                    {
                        title: "Name",
                        headerTooltip: 'Name',
                        field: "firstname",
                        vertAlign: "middle",
                        responsive: 0,
                        formatter: function (cell, formatterParams, onRendered) {
                            let order = cell.getData();
                            return order.firstname + ' ' + order.lastname;
                        }
                    },
                    {
                        title: "Adresse",
                        headerTooltip: 'Adresse',
                        field: "street",
                        vertAlign: "middle",
                        responsive: 0,
                        formatter: function (cell, formatterParams, onRendered) {
                            if (_self.userHasRole(['admin'])) {
                                let pupil = cell.getData();
                                if (pupil.street == null || pupil.zip == null) return '';
                                return pupil.street + ', ' + pupil.zip + ' ' + pupil.city;
                            } else {
                                return '-';
                            }
                        }
                    },
                    {
                        title: "Schüler",
                        headerTooltip: 'Schüler',
                        field: "pupil_name",
                        vertAlign: "middle",
                    },
                    {
                        title: "Schule",
                        headerTooltip: 'Schule',
                        field: "school",
                        vertAlign: "middle",
                        formatter: function (cell, formatterParams, onRendered) {
                            if (_self.userHasRole(['admin'])) {
                                let pupil = cell.getData();
                                return pupil.school;
                            } else {
                                return '-';
                            }
                        }
                    },
                    {
                        title: "Größe",
                        headerTooltip: 'Größe',
                        field: "size",
                        vertAlign: "middle",
                        formatter: function (cell, formatterParams, onRendered) {
                            if (_self.userHasRole(['admin'])) {
                                let pupil = cell.getData();
                                return pupil.size;
                            } else {
                                return '-';
                            }
                        }
                    },
                    {
                        title: "Instrument",
                        headerTooltip: 'Instrument',
                        field: "rentings",
                        vertAlign: "middle",
                        formatter: function (cell, formatterParams, onRendered) {
                            let pupil = cell.getData();
                            if (pupil.rentings != null && pupil.rentings.length > 0) {
                                let res = pupil.rentings.filter(x => x.end == null);
                                if (res.length > 0) {
                                    return res[0].instrument.sku;
                                }
                            }
                        }
                    },
                    {
                        title: "ausgeliehen am",
                        headerTooltip: 'Datum an dem das aktuelle Instrument ausgeliehen wurde',
                        field: "rentings",
                        headerSort: false,
                        vertAlign: "middle",
                        formatter: function (cell, formatterParams, onRendered) {
                            let pupil = cell.getData();
                            if (pupil.rentings != null && pupil.rentings.length > 0) {
                                let res = pupil.rentings.filter(x => x.end == null);
                                if (res.length > 0) {
                                    return dayjs(res[0].start).format('DD.MM.YYYY');
                                }
                            }
                        }
                    },
                    {
                        title: "",
                        minWidth: 60,
                        hozAlign: "center",
                        vertAlign: "middle",
                        headerSort: false,
                        print: false,
                        download: false,
                        formatter(cell) {
                            let rowIndex = cell.getRow().getIndex();
                            let a = dom(`<div class="flex justify-center items-center">
                                  <a
                                      class="btn btn-xs btn-success flex items-center mr-3 edit"
                                      href="javascript:;"
                                      title="bearbeiten"
                                  >
                                      <i data-lucide="wrench" class="w-4 h-4 mr-1 edit"></i>
                                  </a>
                                  <a
                                      class="btn btn-xs btn-warning flex items-center mr-3 trash"
                                      href="javascript:;"
                                      title="löschen"
                                  >
                                      <i data-lucide="trash" class="w-4 h-4 mr-1 trash"></i>
                                  </a>
                              </div>`);

                            if (!_self.userHasRole(['admin'])) {
                                a = dom(`<div class="flex justify-center items-center">
                                    <a
                                        class="btn btn-xs btn-success flex items-center mr-3 edit"
                                        href="javascript:;"
                                        title="bearbeiten"
                                    >
                                        <i data-lucide="wrench" class="w-4 h-4 mr-1 edit"></i>
                                    </a>
                                </div>`);
                            }

                            dom(a, 'a').on("click", function (ev) {
                                let pupil = cell.getData();
                                let elem = null;

                                if (ev.target.nodeName !== 'svg' || ev.target.nodeName !== 'a') {
                                    elem = $(ev.target).parent();
                                } else {
                                    elem = $(ev.target);
                                }

                                if (elem.hasClass('edit')) {
                                    _self.onEdit(pupil, rowIndex);
                                } else if (elem.hasClass('trash')) {
                                    _self.onDelete(pupil);
                                }
                            });

                            return a[0];
                        }
                    }
                ],
                renderComplete() {
                    _self.updateIcons();
                },
            });
        },
    }
}
</script>
