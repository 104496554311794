<template>
    <div class="intro box mt-5" id="producer-form" v-if="loaded">
        <div class="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
            <h2 class="font-medium text-base mr-auto">Hersteller bearbeiten / anlegen</h2>
        </div>
        <div class="p-5">

            <FormErrorBox />

            <div class="form-inline">
                <label for="nr" class="form-label sm:w-40">Name</label>
                <input
                    id="nr"
                    type="text"
                    class="form-control"
                    v-model="form.name"
                >
            </div>
            <div class="px-5 pt-8 pb-8 text-center">
                <button
                    type="button"
                    @click="this.$emit('close')"
                    class="btn btn-outline-warning w-24 mr-1"
                >
                    abbrechen
                </button>
                <button
                    type="button"
                    class="btn btn-success w-24"
                    @click="onSave"
                >
                    speichern
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import FormErrorBox from "./FormErrorBox.vue";
import authHeader from "../services/auth-header";

export default {
    name: "EditStatusForm",
    components: {FormErrorBox},

    props: {
        producer: {
            type: Object,
            default: {},
            required: true,
        },
    },

    data() {
        return {
            loaded  : false,
            form    : {
                name: '',
            },
        }
    },

    mounted() {
        this.form = JSON.parse(JSON.stringify(this.producer));
        this.loaded = true;
    },

    methods: {

        dateUpdate(val) {
            this.trailer.hu = val;
        },

        onSave() {

            $('#producer-form .text-danger').html('');
            $('#producer-form input.border-danger').removeClass('border-danger');

            if (this.form.id == null || this.form.id < 1) {
                this.create();
            } else {
                this.update();
            }
        },

        create() {
            axios
                .post(this.$store.state.config.apiUrl + '/status', this.form, { headers: authHeader() })
                .then(response => {
                    this.$emit('save');

                    this.showNotification(true, 'Speichern', 'Die Daten wurden gespeichert');
                })
                .catch(response => {
                    this.showFormErrors(response.response.data.errors);
                });
        },

        update() {
            axios
                .put(this.$store.state.config.apiUrl + '/status/' + this.form.id, this.form, { headers: authHeader() })
                .then(response => {
                    this.$emit('save');

                    this.showNotification(true, 'Speichern', 'Die Daten wurden gespeichert');
                })
                .catch(response => {
                    this.showFormErrors(response.response.data.errors);
                });
        },

        showFormErrors(error) {
            _.each(error, (message, key) => {
                $('#producer-form #' + key).addClass('border-danger');
                let box = $('#producer-form .text-danger');
                if (box != null) {
                    box.append('<p>' + message[0] + '</p>');
                }
            });
        }
    }
}
</script>

<style scoped>

</style>
