<template>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro box p-5">
        <div class="flex flex-row">
            <div class="flex flex-col w-100 sm:items-end xl:items-start">
                <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto" @submit="onFilter">
                    <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                        <label
                            class="w-32 flex-none xl:w-auto xl:flex-initial mr-2"
                        >
                        </label>
                        <input
                            id="tabulator-html-filter-value"
                            v-model="filter.value"
                            type="text"
                            class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
                            placeholder="Suchtext"
                        />
                    </div>
                    <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                        <label for="type" class="form-label mb-0 mr-2">Typ</label>
                        <select
                            class="form-select sm:mr-2"
                            id="type"
                            aria-label="Hersteller"
                            style="width: auto;"
                            v-model="filter.type_id"
                        >
                            <option v-for="type in types" :value="type.id">
                                {{ type.name }}
                            </option>
                        </select>
                    </div>
                    <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                        <label for="status" class="form-label mb-0 mr-2">Status</label>
                        <select
                            class="form-select sm:mr-2"
                            id="status"
                            aria-label="Status"
                            style="width: auto;"
                            v-model="filter.status_id"
                        >
                            <option v-for="status in statuses" :value="status.id">
                                {{ status.name }}
                            </option>
                        </select>
                    </div>
                    <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                        <label for="size" class="form-label mb-0 mr-2">Größe</label>
                        <select
                            class="form-select sm:mr-2"
                            id="size"
                            aria-label="Größe"
                            style="width: auto;"
                            v-model="filter.size"
                        >
                            <option value="">---</option>
                            <option value="1/16">1/16</option>
                            <option value="1/8">1/8</option>
                            <option value="1/4">1/4</option>
                            <option value="1/2">1/2</option>
                            <option value="3/4">3/4</option>
                            <option value="4/4">4/4</option>
                        </select>
                    </div>
                    <div class="mt-2 xl:mt-0">
                        <button
                            id="tabulator-html-filter-go"
                            type="button"
                            class="btn btn-primary w-full sm:w-24"
                            @click="onFilter"
                        >
                            suche
                        </button>
                        <button
                            id="tabulator-html-filter-reset"
                            type="button"
                            class="btn btn-secondary w-full sm:w-24 mt-2 sm:mt-0 sm:ml-1"
                            @click="onResetFilter"
                        >
                            abbrechen
                        </button>
                    </div>
                </form>
            </div>
        </div>

        <div class="overflow-x-auto scrollbar-hidden">
            <div
                id="tabulator"
                ref="tableRef"
                class="mt-5 table-report table-report--tabulator"
            ></div>
        </div>
    </div>

    <!-- BEGIN: Edit instrument Modal -->
    <Modal
        v-if="editInstrumentModal"
        :show="editInstrumentModal"
        :backdrop="'static'"
        :size="'modal-xxl'"
    >
        <ModalBody class="p-0">
            <EditInstrumentForm
                :instrument="editInstrument"
                :editable="false"
                @save="onEditEnd(true)"
                @close="onEditEnd(false)"
                @hidden="onEditEnd(false)"
            />
        </ModalBody>
    </Modal>
    <!-- END: Edit instrument Modal -->

    <!-- BEGIN: Delete Confirmation Modal -->
    <Modal
        :backdrop="'static'"
        :show="deleteConfirmationModal"
        @hidden="deleteConfirmationModal = false; deleteModel = null; enableScrolling();"
    >
        <ModalBody class="p-0">
            <div class="p-5 text-center">
                <XCircleIcon class="w-16 h-16 text-danger mx-auto mt-3"/>
                <div class="text-3xl mt-5">Archiv</div>
                <div class="text-slate-500 mt-2">
                    Wollen Sie diesen Datensatz wirklich aus dem Archiv zurückholen?
                </div>
            </div>
            <div class="px-5 pb-8 text-center">
                <button
                    type="button"
                    @click="deleteConfirmationModal = false; deleteModel = null; enableScrolling()"
                    class="btn btn-outline-secondary w-24 mr-1"
                >
                    abbrechen
                </button>
                <button
                    type="button"
                    class="btn btn-danger w-24"
                    @click="doUnDelete()"
                >
                    ja
                </button>
            </div>
        </ModalBody>
    </Modal>
    <!-- END: Delete Confirmation Modal -->

</template>

<script>
import axios from "axios";
import dom from "@left4code/tw-starter/dist/js/dom";
import Tabulator from "tabulator-tables";
import EditInstrumentForm from '../../partials/EditInstrumentForm';
import authHeader from "../../services/auth-header";

export default {
    name: "InstrumentsArchive",

    components: {
        EditInstrumentForm,
    },

    data() {
        return {
            editInstrumentModal     : false,
            editInstrument          : null,
            deleteConfirmationModal : false,
            deleteModel             : null,
            tabulator               : null,
            types                   : [],
            statuses                : [],
            filter                  : {
                field: 'name',
                type: '=',
                value: '',
                type_id: '',
                status_id: '',
                size: '',
            }
        }
    },

    mounted() {
        this.initTabulator();
        this.reInitOnResizeWindow();

        axios
            .get(this.$store.state.config.apiUrl + '/categorySimple', { headers: authHeader() })
            .then(response => {
                this.types = response.data.data;
            });

        axios
            .get(this.$store.state.config.apiUrl + '/statusSimple', { headers: authHeader() })
            .then(response => {
                this.statuses = response.data.data;
            });
    },

    methods: {

        onEditEnd(reload) {
            this.editInstrumentModal = false;
            this.editInstrument = null;
            this.enableScrolling();

            if (reload) {
                this.tabulator.replaceData();
            }
        },

        onFilter(ev) {
            if (ev != null) {
                ev.preventDefault();
                ev.stopPropagation();
            }

            this.tabulator.setFilter([
                { 'field': this.filter.field, 'type': this.filter.type, 'value': this.filter.value },
                { 'field': 'type', 'type': this.filter.type, 'value': this.filter.type_id },
                { 'field': 'size', 'type': this.filter.type, 'value': this.filter.size },
                { 'field': 'status', 'type': this.filter.type, 'value': this.filter.status_id }
            ]);
        },

        onResetFilter(ev) {
            this.filter.field = "name";
            this.filter.type = "like";
            this.filter.value = "";
            this.tabulator.clearFilter();
            this.onFilter(ev);
        },

        initTabulator() {
            let _self = this;
            this.tabulator = new Tabulator(this.$refs.tableRef, {
                ajaxURL: this.$store.state.config.apiUrl + '/instrument?deleted=1',
                ajaxFiltering: true,
                ajaxSorting: true,
                ajaxConfig: {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json; charset=utf-8', //set specific content type
                        'Authorization': 'Bearer ' + this.$store.state.auth.accessToken
                    }
                },
                ajaxError: function(response) {
                    if (401 === response.status) {
                        _self.$store.dispatch('auth/logout', this.$store.state.auth.user);
                        _self.$router.push('/login');
                    } else {
                        return Promise.reject(response);
                    }
                },
                printAsHtml: true,
                printStyled: true,
                pagination: "remote",
                paginationSize: 15,
                paginationSizeSelector: [10, 15, 25, 50],
                layout: "fitColumns",
                responsiveLayout:"hide",
                placeholder: "Es wurden keine Ergebnisse gefunden",
                columns: [
                    {
                        title: "Inv.Nr.",
                        headerTooltip: 'Inventarnummer',
                        field: "sku",
                        vertAlign: "middle",
                        responsive: 0
                    },
                    {
                        title: "Seriennr.",
                        headerTooltip: 'Seriennummer',
                        field: "serial_number",
                        vertAlign: "middle",
                        responsive: 0
                    },
                    {
                        title: "Typ",
                        headerTooltip: 'Typ',
                        field: "category.name",
                        vertAlign: "middle",
                        responsive: 0
                    },
                    {
                        title: "Hersteller",
                        headerTooltip: 'Hersteller',
                        field: "producer.name",
                        vertAlign: "middle",
                    },
                    {
                        title: "Schule",
                        headerTooltip: 'Schule',
                        field: "school.name",
                        vertAlign: "middle",
                    },
                    {
                      title: "Größe",
                      headerTooltip: 'Größe',
                      field: "size",
                      vertAlign: "middle",
                    },
                    {
                      title: "Status",
                      headerTooltip: 'Status',
                      field: "status.name",
                      vertAlign: "middle",
                    },
                    {
                        title: "",
                        minWidth: 60,
                        hozAlign: "center",
                        vertAlign: "middle",
                        headerSort: false,
                        print: false,
                        download: false,
                        formatter: function(cell) {

                            const a = dom(`<div class="flex justify-center items-center">
                                <a
                                    class="btn btn-xs btn-success flex items-center mr-3 edit"
                                    href="javascript:;"
                                    title="bearbeiten"
                                >
                                    <i data-lucide="wrench" class="w-4 h-4 mr-1 edit"></i>
                                </a>
                                <a
                                    class="btn btn-xs btn-warning flex items-center mr-3 rotate-ccw"
                                    href="javascript:;"
                                    title="aus dem Archiv zurückholen"
                                >
                                    <i data-lucide="rotate-ccw" class="w-4 h-4 mr-1 rotate-ccw"></i>
                                </a>
                            </div>`);

                            dom(a, 'a').on("click", function (ev) {
                                let instrument = cell.getData();
                                let elem = null;

                                if (ev.target.nodeName !== 'svg' || ev.target.nodeName !== 'a') {
                                    elem = $(ev.target).parent();
                                } else {
                                    elem = $(ev.target);
                                }

                                if (elem.hasClass('edit')) {
                                    _self.onEdit(instrument);
                                } else if (elem.hasClass('rotate-ccw')) {
                                  _self.onDelete(instrument);
                                }
                            });

                            return a[0];
                        }
                    }
                ],
                renderComplete() {
                    _self.updateIcons();
                },
            });
        },

        onEdit(order) {
            this.editInstrument = order;
            this.editInstrumentModal = true;
        },

        onDelete(order) {
            this.deleteModel = order;
            this.deleteConfirmationModal = true;
        },

        doUnDelete() {
            this.deleteModel.undelete = 1;

            axios
                .put(this.$store.state.config.apiUrl + '/instrument/' + this.deleteModel.id, this.deleteModel, { headers: authHeader() })
                .then(response => {
                    this.tabulator.deleteRow(this.deleteModel.id);
                    this.deleteModel = null;
                    this.deleteConfirmationModal = false;

                    this.showNotification(true, 'Archivieren', 'Der Datensatz wurde aus dem Archiv entfernt');
                })
                .catch(error => {
                    this.showNotification(true, 'Archivieren', error.response.data.message);
                });
        },
    }
}
</script>
