import AuthService from '../services/auth.service';

const initialState = {
    status: {
        loggedIn: false
    },
    user: null,
    accessToken: null,
};

export const auth = {
    namespaced  : true,
    state       : initialState,

    actions: {
        login({commit}, user) {
            return AuthService.login(user).then(
                response => {
                    commit('loginSuccess', response);
                    return Promise.resolve(response.user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        logout({commit}, user) {
            AuthService.logout(user).then(
                response => {
                    commit('logout');
                    return Promise.resolve(response.message);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        }
    },

    getters: {
        isLoggedIn(state, getters) {
            return state.status.loggedIn;
        },
        user(state, getters) {
            return state.user;
        }
    },

    mutations: {
        loginSuccess(state, response) {
            state.status.loggedIn = true;
            state.user = response.user;
            state.accessToken = response.accessToken;
            window.axios.defaults.headers.common['Authorization'] = `Bearer ${response.accessToken}`;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.accessToken = null;
            state.user = null;
            window.axios.defaults.headers.common['Authorization'] = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.accessToken = null;
            state.user = null;
            window.axios.defaults.headers.common['Authorization'] = null;
        }
    }
};
