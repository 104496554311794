<template>
    <div class="intro box mt-5" id="pupil-form" v-if="this.loaded">
        <div class="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
            <h2 class="font-medium text-base mr-auto">Schüler bearbeiten / anlegen</h2>
        </div>

        <div  class="grid grid-cols-12 gap-6 pl-5 pr-5" v-if="showUploadForm">
            <div class="intro col-span-12 lg:col-span-12 pb-4">
                <button
                    type="button"
                    style="float:right"
                    class="btn btn-primary w-48"
                    @click="filesUploadedDone"
                >
                  zurück
                </button>
                <div class="text-center mt-6 mb-4">
                    <h3 class="text-2xl dark:text-white">Datei hochladen</h3>
                </div>
                <Upload :id="this.form.id" :type="'pupilDocument'" @upload-done="filesUploaded" @close="filesUploadedDone" />
            </div>
        </div>

        <div class="grid grid-cols-12 gap-6 pl-5 pr-5" v-if="showInstrumentDetails">
            <div class="intro col-span-12 lg:col-span-12 pb-4">
                <ShowInstrumentForm :instrument="instrument" @close="showInstrumentDetails=false;instrument=null;" />
            </div>
        </div>

        <div  v-if="!showUploadForm && !showInstrumentDetails">
            <div class="grid grid-cols-12 gap-6 pl-0 pr-5">

                <FormErrorBox />

                <div class="intro col-span-12 lg:col-span-6 pb-4">
                    <div class="form-inline">
                        <label for="pupil_number" class="form-label sm:w-40">Nummer</label>
                        <input
                            id="pupil_number"
                            type="text"
                            class="form-control"
                            placeholder=""
                            v-model="form.pupil_number"
                        />
                    </div>
                    <div class="form-inline mt-5">
                        <label for="pupil_name" class="form-label sm:w-40">Schüler</label>
                        <input
                            id="pupil_name"
                            type="text"
                            class="form-control"
                            placeholder=""
                            v-model="form.pupil_name"
                        />
                    </div>
                    <div class="form-inline mt-5">
                        <label for="lastname" class="form-label sm:w-40">Nachname</label>
                        <input
                            id="lastname"
                            type="text"
                            class="form-control"
                            placeholder=""
                            v-model="form.lastname"
                        />
                    </div>
                    <div class="form-inline mt-5">
                        <label for="firstname" class="form-label sm:w-40">Vorname</label>
                        <input
                            id="firstname"
                            type="text"
                            class="form-control"
                            placeholder=""
                            v-model="form.firstname"
                        />
                    </div>
                    <div class="form-inline mt-5" v-if="userHasRole(['admin'])">
                        <label for="street" class="form-label sm:w-40">Straße</label>
                        <input
                            id="street"
                            type="text"
                            class="form-control"
                            placeholder=""
                            v-model="form.street"
                        />
                    </div>
                    <div class="form-inline mt-5" v-if="userHasRole(['admin'])">
                        <label for="zip" class="form-label sm:w-40">PLZ</label>
                        <input
                            id="zip"
                            type="text"
                            class="form-control"
                            placeholder=""
                            v-model="form.zip"
                        />
                    </div>
                    <div class="form-inline mt-5" v-if="userHasRole(['admin'])">
                        <label for="city" class="form-label sm:w-40">Stadt</label>
                        <input
                            id="city"
                            type="text"
                            class="form-control"
                            placeholder=""
                            v-model="form.city"
                        />
                    </div>
                    <div class="form-inline mt-5" v-if="userHasRole(['admin'])">
                        <label for="instrument" class="form-label sm:w-40">Instrument</label>
                        <input
                            id="instrument"
                            type="text"
                            class="form-control"
                            placeholder=""
                            v-model="form.instrument"
                        />
                    </div>
                </div>

                <div class="intro col-span-12 lg:col-span-6 pb-4" v-if="userHasRole(['admin'])">
                    <div class="form-inline mt-5">
                        <label for="email" class="form-label sm:w-40">E-Mail</label>
                        <input
                            id="email"
                            type="text"
                            class="form-control"
                            placeholder=""
                            v-model="form.email"
                        />
                    </div>
                    <div class="form-inline mt-5">
                        <label for="phone" class="form-label sm:w-40">Telefon</label>
                        <input
                            id="phone"
                            type="text"
                            class="form-control"
                            placeholder=""
                            v-model="form.phone"
                        />
                    </div>
                    <div class="form-inline mt-5">
                        <label for="mobile" class="form-label sm:w-40">Handy</label>
                        <input
                            id="mobile"
                            type="text"
                            class="form-control"
                            placeholder=""
                            v-model="form.mobile"
                        />
                    </div>
                    <div class="form-inline mt-5">
                        <label for="school" class="form-label sm:w-40">Schule</label>
                        <input
                            id="school"
                            type="text"
                            class="form-control"
                            placeholder=""
                            v-model="form.school"
                        />
                    </div>
                    <div class="form-inline mt-5">
                        <label for="teacher" class="form-label sm:w-40">Musikschule</label>
                        <input
                            id="teacher"
                            type="text"
                            class="form-control"
                            placeholder=""
                            v-model="form.teacher"
                        />
                    </div>
                    <div class="form-inline mt-5">
                        <label for="class" class="form-label sm:w-40">Klasse</label>
                        <input
                            id="class"
                            type="text"
                            class="form-control"
                            placeholder=""
                            v-model="form.class"
                        />
                    </div>
                    <div class="form-inline mt-5">
                        <label for="comment" class="form-label sm:w-40">Kommentar</label>
                        <textarea
                            id="comment"
                            class="form-control"
                            v-model="form.comment"
                        ></textarea>
                    </div>
                </div>
            </div>

            <div class="grid grid-cols-12 gap-6 pr-5 pl-5">
                <div class="intro col-span-12 lg:col-span-12 pb-4">
                    <div class="text-center mt-6 mb-4">
                        <h3 class="text-2xl dark:text-white">Historie</h3>
                    </div>
                    <table class="table table-bordered table-condensed table-hover table-striped">
                        <thead>
                        <tr>
                            <th>Von</th>
                            <th>Bis</th>
                            <th>Instrument</th>
                            <th>Kommentar</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-if="!form.rentings || form.rentings.length < 1">
                                <td colspan="5">es liegen keine Daten vor</td>
                            </tr>
                            <tr v-for="renting in form.rentings" :key="renting.id">
                                <td>{{ dateFormat(renting.start) }}</td>
                                <td>{{ dateFormat(renting.end) }}</td>
                                <td>
                                    <a href="#" @click="showInstrument(renting.instrument)">
                                        {{ renting.instrument.sku }}
                                    </a>
                                </td>
                                <td>{{ renting.comment }}</td>
                                <td>
                                    <a
                                        v-if="userHasRole(['admin'])"
                                        class="btn btn-xs btn-danger items-center mr-3"
                                        href="javascript:;"
                                        title="löschen"
                                        @click="onDeleteRenting(renting)"
                                    >
                                        <i data-lucide="trash" class="w-4 h-4 mr-1 trash"></i>
                                    </a>
                                    <a
                                        v-if="userHasRole(['admin']) && (renting.end == null || renting.end === '')"
                                        class="btn btn-xs btn-primary items-center mr-3"
                                        :href="'/api/v1/makeRentingDocument/'+renting.id+'/'+this.$store.state.auth.accessToken"
                                        title="Leihschein erstellen"
                                        target="_blank"
                                    >
                                      <i data-lucide="file-signature" class="w-4 h-4 mr-1 file"></i>
                                    </a>
                                    <a
                                        v-if="userHasRole(['admin']) && (renting.end == null || renting.end === '')"
                                        class="btn btn-xs btn-primary items-center mr-3"
                                        :href="'/api/v1/makeDamageReportDocument/'+renting.id+'/'+this.$store.state.auth.accessToken"
                                        title="Schaden melden"
                                        target="_blank"
                                    >
                                        <i data-lucide="hammer" class="w-4 h-4 mr-1 file"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="intro col-span-12 lg:col-span-12 pb-4">
                    <div class="text-center mt-6 mb-4">
                        <h3 class="text-2xl dark:text-white">Dateien</h3>
                    </div>
                    <table class="table table-bordered table-condensed table-hover table-striped">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>hochgeladen am</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="!form.documents || form.documents.length < 1">
                                <td colspan="3">es liegen keine Daten vor</td>
                            </tr>
                            <tr v-for="document in form.documents" :key="document.id">
                                <td><a target="_blank" :href="'/uploads/' + document.name">{{ document.name }}</a></td>
                                <td>{{ dateFormat(document.created_at) }}</td>
                                <td>
                                    <a
                                        v-if="userHasRole(['admin'])"
                                        class="btn btn-xs btn-danger flex items-center mr-3"
                                        href="javascript:;"
                                        title="löschen"
                                        @click="onDeleteFile(document)"
                                    >
                                        <i data-lucide="trash" class="w-4 h-4 mr-1 trash"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <button
                        v-if="this.form.id > 0 && userHasRole(['admin'])"
                        type="button"
                        @click="onUpload"
                        class="btn btn-primary w-48 mt-2"
                    >
                        Datei hochladen
                    </button>
                </div>
            </div>

            <div class="grid grid-cols-1">
                <div class="px-5 pt-8 pb-8 text-center">
                    <button
                        type="button"
                        @click="onClose"
                        class="btn btn-outline-warning w-24 mr-1"
                    >
                        abbrechen
                    </button>
                    <button
                        v-if="userHasRole(['admin'])"
                        type="button"
                        class="btn btn-success w-24"
                        @click="onSave"
                    >
                        speichern
                    </button>
                </div>
            </div>
        </div>

        <!-- BEGIN: Delete Confirmation Modal -->
        <Modal
            :backdrop="'static'"
            :show="deleteConfirmationModal"
            @hidden="onDeleteEnd()"
        >
            <ModalBody class="p-0">
                <div class="p-5 text-center">
                    <XCircleIcon class="w-16 h-16 text-danger mx-auto mt-3"/>
                    <div class="text-3xl mt-5">Löschen</div>
                    <div class="text-slate-500 mt-2">
                        Wollen Sie diesen Datensatz wirklich löschen?
                    </div>
                </div>
                <div class="px-5 pb-8 text-center">
                    <button
                        type="button"
                        @click="onDeleteEnd()"
                        class="btn btn-outline-secondary w-24 mr-1"
                    >
                        abbrechen
                    </button>
                    <button
                        type="button"
                        class="btn btn-danger w-24"
                        @click="doDelete()"
                    >
                        ja
                    </button>
                </div>
            </ModalBody>
        </Modal>
        <!-- END: Delete Confirmation Modal -->

        <!-- BEGIN: Delete Confirmation Modal -->
        <Modal
            :backdrop="'static'"
            :show="deleteRentingConfirmationModal"
            @hidden="onDeleteRentingEnd()"
        >
            <ModalBody class="p-0">
                <div class="p-5 text-center">
                    <XCircleIcon class="w-16 h-16 text-danger mx-auto mt-3"/>
                    <div class="text-3xl mt-5">Löschen</div>
                    <div class="text-slate-500 mt-2">
                        Wollen Sie diesen Datensatz wirklich löschen?
                    </div>
                </div>
                <div class="px-5 pb-8 text-center">
                    <button
                        type="button"
                        @click="onDeleteRentingEnd()"
                        class="btn btn-outline-secondary w-24 mr-1"
                    >
                        abbrechen
                    </button>
                    <button
                        type="button"
                        class="btn btn-danger w-24"
                        @click="doDeleteRenting()"
                    >
                        ja
                    </button>
                </div>
            </ModalBody>
        </Modal>
        <!-- END: Delete Confirmation Modal -->
    </div>
</template>

<script>
import axios from "axios";
import dayjs from 'dayjs';
import VueMultiselect from 'vue-multiselect';
import VueTimepicker from "vue3-timepicker";
import Upload from "./Upload.vue";
import ShowPupilForm from "./ShowPupilForm.vue";
import ShowInstrumentForm from "./ShowInstrumentForm.vue";
import FormErrorBox from "./FormErrorBox.vue";
import authHeader from "../services/auth-header";

export default {
    name: "EditPupilForm",

    components: {
        FormErrorBox,
        ShowInstrumentForm,
        ShowPupilForm,
        Upload,
        VueMultiselect,
        VueTimepicker
    },

    props: {
        pupil: {
            type: Object,
            required: true,
            default: {}
        }
    },

    data() {
        return {
            showUploadForm                 : false,
            showInstrumentDetails          : false,
            deleteModel                    : null,
            deleteConfirmationModal         : false,
            deleteRentingConfirmationModal  : false,
            deleteRentingModel             : null,
            instrument                     : null,
            loaded                         : false,
            form                           : {}
        }
    },

    mounted() {
        this.form = JSON.parse(JSON.stringify(this.pupil));
        this.updateIcons();

        if (this.form.pupil_number == null || this.form.pupil_number === '') {
            let payload = { format: dayjs().format('MYYYY-') };
            axios
                .post(this.$store.state.config.apiUrl + '/pupil_number', payload, { headers: authHeader() })
                .then(response => {
                    this.form.pupil_number = response.data.number;
                })
                .catch(response => {
                    this.showFormErrors(response.response.data.errors);
                });
        }
    },

    watch: {
        form: function(newValue, oldValue) {
            this.form = newValue;
            this.loaded = true;
        }
    },

    methods: {

        showInstrument(instrument) {
            this.showInstrumentDetails = true;
            this.instrument = instrument;
        },

        onUpload() {
            this.showUploadForm = true;
        },

        filesUploaded(file) {
          this.form.documents.push(file);
        },

        filesUploadedDone() {
          this.showUploadForm = false;
          this.updateIcons();
        },

        //---- Remote API Calls -----//
        onSave() {
            $('#pupil-form .text-danger').html('');
            $('#pupil-form input.border-danger').removeClass('border-danger');

            if (this.form.pupil_number == null || this.form.pupil_number === '') {
                this.showFormErrors({
                    "pupil_number": [
                        "Bitte geben Sie den Sch\u00fclernummer an"
                    ]
                });
                return;
            }

            if (this.form.pupil_name == null || this.form.pupil_name === '') {
                this.showFormErrors({
                    "pupil_name": [
                        "Bitte geben Sie den Sch\u00fclernamen an"
                    ]
                });
                return;
            }

            if (this.form.id == null || this.form.id < 1) {
                this.create();
            } else {
                this.update();
            }
        },

        update() {
            axios
                .put(this.$store.state.config.apiUrl + '/pupil/' + this.form.id, this.form, { headers: authHeader() })
                .then(response => {
                    this.$emit('save', response.data.item);

                    this.showNotification(true, 'Speichern', 'Die Daten wurden gespeichert');
                })
                .catch(response => {
                    this.showFormErrors(response.response.data.errors);
                });
        },

        create() {
            axios
                .post(this.$store.state.config.apiUrl + '/pupil', this.form, { headers: authHeader() })
                .then(response => {
                    if (response.status !== 200) {
                        this.showNotification(true, 'Speichern', 'Die Daten wurden nicht gespeichert');
                    } else {
                        this.$emit('save', response.data.item);

                        this.showNotification(true, 'Speichern', 'Die Daten wurden gespeichert');
                    }
                })
                .catch(response => {
                    this.showFormErrors(response.response.data.errors);
                });
        },

        onClose() {
            this.$emit('close', this.form);

            for (let x = 0; x < this.form.length; x++) {
                this.form[x] = '';
            }
        },

        showFormErrors(error) {
            _.each(error, (message, key) => {
                key = key.replace('order.', '');
                $('#pupil-form #' + key).addClass('border-danger');
                let box = $('#pupil-form .text-danger');
                if (box != null) {
                    box.append('<p>' + message[0] + '</p>');
                }
            });
            $('.modal').animate({ scrollTop: 0}, 'fast');
        },

        doDelete() {
            axios
                .delete(this.$store.state.config.apiUrl + '/document/' + this.deleteModel.id, { headers: authHeader() })
                .then(response => {
                    this.form.documents = this.form.documents.filter(x => x.id !== this.deleteModel.id);
                    this.onDeleteEnd();

                    this.showNotification(true, 'Löschen', 'Der Datensatz wurde gelöscht');
                })
                .catch(error => {
                    this.showNotification(true, 'Löschen', error.response.data.message);
                });
        },

        onDeleteEnd() {
            this.deleteConfirmationModal = false;
            this.deleteModel = null;
            this.updateIcons();
        },

        onDeleteFile(document) {
            this.deleteModel = document;
            this.deleteConfirmationModal = true;
        },

        onDeleteRenting(renting) {
            this.deleteRentingModel = renting;
            this.deleteRentingConfirmationModal = true;
        },

        onDeleteRentingEnd() {
            this.deleteRentingConfirmationModal = false;
            this.deleteRentingModel = null;
            this.updateIcons();
        },

        doDeleteRenting() {
            axios
                .delete(this.$store.state.config.apiUrl + '/renting/' + this.deleteRentingModel.id, { headers: authHeader() })
                .then(response => {
                    this.form.rentings = this.form.rentings.filter(x => x.id !== this.deleteRentingModel.id);
                    this.onDeleteRentingEnd();

                    this.darkModeStore.setDarkMode(true);
                    this.darkModeStore.setDarkModeText('Löschen');
                    this.darkModeStore.setDarkModeLongText('Der Datensatz wurde gelöscht');
                })
                .catch(error => {
                    this.darkModeStore.setDarkMode(true);
                    this.darkModeStore.setDarkModeText('Löschen');
                    this.darkModeStore.setDarkModeLongText(error.response.data.message);
                });
        },
    }
}
</script>

<style>

</style>
