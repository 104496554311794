<template>
    <div class="intro box mt-5" id="rental-form" v-if="this.loaded">
        <div class="grid grid-cols-12 gap-6 pl-0 pr-5">

            <FormErrorBox />

            <div class="intro col-span-12 lg:col-span-6 pb-4">
                <div class="text-center mb-4">
                    <h2 class="text-2xl dark:text-white">Informationen</h2>
                </div>

                <div class="form-inline mt-5" style="margin-bottom: 16px;">
                    <label for="end" class="form-label sm:w-40">Rückgabe-Datum</label>
                    <VueDatePicker
                        format="dd.MM.yyyy"
                        locale="de"
                        cancelText="abbrechen"
                        selectText="auswählen"
                        v-model="form.end"
                        :auto-apply="true"
                        class="form-control"
                        id="end"
                        autocomplete="off"
                        style="max-width: 440px;"
                    />
                </div>

                <div class="form-inline mt-5">
                    <label for="comment" class="form-label sm:w-40">Kommentar</label>
                    <textarea
                        id="comment"
                        class="form-control"
                        v-model="form.comment"
                    ></textarea>
                </div>
            </div>
        </div>

        <div class="grid grid-cols-1">
            <div class="px-5 pt-8 pb-8 text-center">
                <button
                    type="button"
                    @click="onClose"
                    class="btn btn-outline-warning w-24 mr-1"
                >
                    abbrechen
                </button>
                <button
                    type="button"
                    class="btn btn-success w-24"
                    @click="onSave"
                >
                    speichern
                </button>
            </div>
        </div>

    </div>
</template>

<script>
import axios from "axios";
import dayjs from 'dayjs';
import VueMultiselect from 'vue-multiselect';
import VueTimepicker from "vue3-timepicker";
import {Tab} from "../global-components/tab";
import FormErrorBox from "./FormErrorBox.vue";
import authHeader from "../services/auth-header";

export default {
    name: "EditRentalForm",

    components: {FormErrorBox, Tab, VueMultiselect, VueTimepicker },

    props: {
        rental: {
            type: Object,
            required: false,
            default: {}
        }
    },

    data() {
        return {
            loaded : true,
            form   : {}
        }
    },

    mounted() {
        this.form = JSON.parse(JSON.stringify(this.rental));

        if (this.form.end == null || this.form.end === '') {
            this.form.end = dayjs().format('YYYY-MM-DD');
        }
    },

    watch: {
        form: function(newValue, oldValue) {
            this.form = newValue;
        }
    },

    methods: {

        //---- Remote API Calls -----//
        onSave() {
            $('#rental-form .text-danger').html('');
            $('#rental-form input.border-danger').removeClass('border-danger');

            let options = { year: "numeric", month: "2-digit", day: "2-digit" };
            this.form.end = this.form.end.toLocaleString('en-US', options);

            this.update();
        },

        update() {
            axios
                .put(this.$store.state.config.apiUrl + '/renting/' + this.form.id, this.form, { headers: authHeader() })
                .then(response => {
                    this.$emit('save', response.data.item);

                    window.open('/api/v1/makeReturnDocument/'+response.data.item.id+'/'+this.$store.state.auth.accessToken, '_blank');

                    this.showNotification(true, 'Speichern', 'Die Daten wurden gespeichert');
                })
                .catch(response => {
                    this.showFormErrors(response.response.data.errors);
                });
        },

        onClose() {
            for (let x = 0; x < this.form.length; x++) {
                this.form[x] = '';
            }
            this.$emit('close');
        },

        showFormErrors(error) {
            _.each(error, (message, key) => {
                key = key.replace('order.', '');
                $('#rental-form #' + key).addClass('border-danger');
                let box = $('#rental-form .text-danger');
                if (box != null) {
                    box.append('<p>' + message[0] + '</p>');
                }
            });
            $('.modal').animate({ scrollTop: 0}, 'fast');
        }
    }
}
</script>

<style>

</style>
